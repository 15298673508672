import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
    Box, Typography, Button, List, ListItem, ListItemText,
    Checkbox, Dialog, DialogTitle, DialogContent, DialogActions,
    Divider, IconButton, Tooltip, Tabs, Tab, Paper, Card, CardContent,
    useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ListAltIcon from '@mui/icons-material/ListAlt'; 
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RefreshIcon from '@mui/icons-material/Refresh';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { ThemeContext } from '../../contexts/ThemeContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`inci-tabpanel-${index}`}
          aria-labelledby={`inci-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box sx={{ pt: 2 }}>
                  {children}
              </Box>
          )}
      </div>
  );
}

function a11yProps(index) {
  return {
      id: `inci-tab-${index}`,
      'aria-controls': `inci-tabpanel-${index}`,
  };
}

const InciNamesList = ({ inciNames, onUpdate, source, onRecalculate }) => {
    const theme = useTheme();
    const { mode } = useContext(ThemeContext);
    
    const [list, setList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isCombineDialogOpen, setIsCombineDialogOpen] = useState(false);
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    // Initialize list when inciNames prop changes
    useEffect(() => {
        console.log("⚙️ InciNamesList received new inciNames prop:", JSON.stringify(inciNames));
        console.log("⚙️ Source:", source);

        let listData = [];
        // Use flat array primarily
        if (inciNames?.flat && Array.isArray(inciNames.flat)) {
            listData = [...inciNames.flat]; // Deep copy just in case
        } else if (Array.isArray(inciNames)) { // Handle legacy array format just in case
            console.warn("⚙️ InciNamesList received legacy array format for inciNames.");
            listData = [...inciNames];
        } else {
            listData = []; // Default to empty if format is unexpected
        }
        setList(listData);

        // Reset local 'hasChanges' and edit mode when props change *unless* the source is 'edited'
        // This prevents resetting edits if the parent re-renders because the user just saved edits
        if (source !== 'edited') {
            console.log("⚙️ Resetting local edit state as source is not 'edited'.");
            setIsEditMode(false);
            setSelectedItems([]);
            setHasChanges(false);
        } else {
            console.log("⚙️ Source is 'edited', preserving local edit state.");
            // Keep local state as is
        }

    }, [inciNames, source]);

    // Toggle edit mode (Save local changes if applicable)
    const toggleEditMode = () => {
        if (isEditMode && hasChanges) {
            // Prepare the data structure parent expects
            const updatedData = {
                flat: [...list], // Send the current local list
                // Keep hierarchical same as flat for now, or sync if needed
                hierarchical: [...list]
            };
            console.log("💾 InciNamesList saving changes via onUpdate:", updatedData);
            onUpdate(updatedData); // Call parent handler (IngredientsList)
            setHasChanges(false); // Reset local changes flag *after* successfully calling parent
        } else if (isEditMode && !hasChanges) {
            // Exiting edit mode without changes
            console.log("Exiting edit mode, no changes to save locally.");
        } else {
            // Entering edit mode
            console.log("Entering edit mode.");
        }
        setIsEditMode(!isEditMode); // Toggle mode
        setSelectedItems([]); // Clear selections when toggling mode
    };

    // Handle selection of an ingredient
    const handleSelectItem = (index) => {
        setSelectedItems(prev => {
            const newSelection = prev.includes(index)
                ? prev.filter(i => i !== index)
                : [...prev, index];
            console.log("Selected items:", newSelection);
            return newSelection;
        });
    };

    // Determine if selected items are valid for merging
    const canMergeSelections = () => {
        return selectedItems.length >= 2;
    };

    // Open combine dialog
    const handleOpenCombineDialog = () => {
        if (selectedItems.length >= 2) {
            setIsCombineDialogOpen(true);
        }
    };

    // --- CORRECTED handleCombineIngredients ---
    const handleCombineIngredients = () => {
        console.log("🔄 Starting to combine ingredients. Selected:", selectedItems);

        if (!canMergeSelections()) {
             console.warn("Attempted to combine with less than 2 items selected.");
             setIsCombineDialogOpen(false);
             return;
        }

        // Sort indices in descending order to avoid index shifting during removal/filtering
        const sortedIndices = [...selectedItems].sort((a, b) => b - a);

        // Find the lowest index among selected items - this will be the position for the combined item
        const targetIndex = Math.min(...selectedItems);
        const targetIngredientData = list[targetIndex]; // Get data of the first item to potentially keep its name

        // Calculate total concentration and gather original ingredients
        let totalConcentration = 0;
        const originalIngredients = [];

        selectedItems.forEach(idx => {
            const ingredient = list[idx];
            if (ingredient) {
                const concentration = parseFloat(ingredient.concentration);
                totalConcentration += isNaN(concentration) ? 0 : concentration;
                // Store details of ingredients being combined
                originalIngredients.push({
                    inciName: ingredient.inciName || ingredient.inci_name, // Handle both possible key names
                    concentration: ingredient.concentration
                });
            }
        });

        // Create the new combined ingredient object
        const combinedIngredient = {
            // Use the INCI name of the item at the lowest selected index
            inciName: targetIngredientData?.inciName || targetIngredientData?.inci_name || "Combined Ingredient",
            concentration: totalConcentration.toFixed(2),
            isCombined: true,
            originalIngredients: originalIngredients,
        };
        console.log("✨ Combined ingredient created:", combinedIngredient);

        // Create a new list: filter out *all* selected items first
        const listWithoutSelected = list.filter((_, idx) => !selectedItems.includes(idx));

        // Insert the new combined ingredient at the calculated target index
        listWithoutSelected.splice(targetIndex, 0, combinedIngredient);
        console.log("📊 New list after combining:", listWithoutSelected);

        // Update state
        setList(listWithoutSelected);
        setSelectedItems([]);
        setIsCombineDialogOpen(false);
        setHasChanges(true); // Mark that local changes have been made
    };

    // Open remove confirmation dialog
    const handleOpenRemoveDialog = (index) => {
        setItemToRemove(index);
        setIsRemoveDialogOpen(true);
    };

    // --- CORRECTED handleRemoveItem ---
    const handleRemoveItem = () => {
        if (itemToRemove === null || itemToRemove < 0 || itemToRemove >= list.length) {
            console.warn("Invalid item index to remove:", itemToRemove);
            setIsRemoveDialogOpen(false);
            return;
        }

        console.log(`🔥 Removing item at index ${itemToRemove}:`, list[itemToRemove]);
        // Create the new list by filtering out the item at the specified index
        const newList = list.filter((_, idx) => idx !== itemToRemove);

        setList(newList); // Update the list state
        setItemToRemove(null);
        setIsRemoveDialogOpen(false);
        setHasChanges(true); // Mark local changes
    };

    // --- Handler for Tab Change ---
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        // If switching away from detailed view, exit edit mode (optional)
        if (newValue !== 0 && isEditMode) {
            toggleEditMode(); // This will trigger save if changes exist
        }
    };

    // --- Calculate Final INCI String ---
    const finalInciString = useMemo(() => {
        if (!list || list.length === 0) return "N/A";

        // Sort according to standard INCI rules (descending concentration, alphabetical for <1%)
        const sortedList = [...list].sort((a, b) => {
            const concA = parseFloat(a.concentration) || 0;
            const concB = parseFloat(b.concentration) || 0;

            if (concA >= 1 && concB >= 1) {
                return concB - concA; // Sort descending > 1%
            } else if (concA < 1 && concB < 1) {
                // Sort alphabetically < 1% (case-insensitive)
                const nameA = (a.inciName || a.inci_name || "").toUpperCase();
                const nameB = (b.inciName || b.inci_name || "").toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            } else if (concA >= 1 && concB < 1) {
                return -1; // Ingredients >= 1% come first
            } else { // concA < 1 && concB >= 1
                return 1; // Ingredients >= 1% come first
            }
        });

        // Join names with ", "
        return sortedList.map(item => item.inciName || item.inci_name).join(", ");

    }, [list]);

    // If no ingredients, render message
    if (!list || list.length === 0) {
        return (
            <Card 
                elevation={1} 
                sx={{ 
                    width: "100%", 
                    mt: 4, 
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
                }}
            >
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6" fontWeight="600">
                            INCI Names
                        </Typography>
                        <Tooltip title="Recalculate INCI list based on current ingredients and concentrations. This will overwrite manual changes.">
                            <IconButton
                                onClick={onRecalculate}
                                size="small"
                                color="primary"
                                sx={{ ml: 1 }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box 
                        sx={{
                            p: 3,
                            textAlign: "center",
                            border: `1px dashed ${theme.palette.divider}`,
                            borderRadius: 2,
                            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)'
                        }}
                    >
                        <Typography variant="body1" color="text.secondary">
                            INCI names will be loaded or calculated here. Use the Refresh icon to recalculate if needed.
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        );
    }
    
    // --- Main Render ---
    return (
        <Card 
            elevation={1} 
            sx={{
                width: "100%",
                borderRadius: 2,
                overflow: 'hidden'
            }}
        >
            <CardContent sx={{ p: 0 }}>
                {/* --- Tabs --- */}
                <Box 
                    sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                        <Typography variant="h6" fontWeight="600">
                            INCI Names
                        </Typography>
                        <Tooltip title="Recalculate INCI list based on current ingredients and concentrations. This will overwrite manual changes.">
                            <IconButton
                                onClick={onRecalculate}
                                size="small"
                                color="primary"
                                sx={{ ml: 1 }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Tabs 
                        value={currentTab} 
                        onChange={handleTabChange} 
                        aria-label="INCI View Tabs"
                        indicatorColor="primary"
                        sx={{
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                fontWeight: 500,
                                py: 1.5
                            }
                        }}
                    >
                        <Tab 
                            label="Detailed View" 
                            icon={<ListAltIcon />} 
                            iconPosition="start" 
                            {...a11yProps(0)} 
                        />
                        <Tab 
                            label="Final List" 
                            icon={<FormatListBulletedIcon />} 
                            iconPosition="start" 
                            {...a11yProps(1)} 
                        />
                    </Tabs>
                </Box>

                {/* --- Tab Panel 0: Detailed View (Editable List) --- */}
                <TabPanel value={currentTab} index={0}>
                    <Box sx={{
                        p: 3,
                        // Apply styling based on edit/changes
                        border: isEditMode 
                            ? `2px solid ${theme.palette.primary.main}` 
                            : hasChanges 
                                ? `2px solid ${theme.palette.warning.main}` 
                                : `1px solid ${theme.palette.divider}`,
                        borderRadius: 2,
                        backgroundColor: isEditMode 
                            ? (mode === 'dark' ? 'rgba(10, 132, 255, 0.1)' : 'rgba(0, 122, 255, 0.05)') 
                            : hasChanges 
                                ? (mode === 'dark' ? 'rgba(255, 159, 10, 0.1)' : 'rgba(255, 149, 0, 0.05)') 
                                : 'background.paper',
                        boxShadow: isEditMode 
                            ? `0 0 8px ${mode === 'dark' ? 'rgba(10, 132, 255, 0.4)' : 'rgba(0, 122, 255, 0.2)'}` 
                            : hasChanges 
                                ? `0 0 8px ${mode === 'dark' ? 'rgba(255, 159, 10, 0.4)' : 'rgba(255, 149, 0, 0.2)'}`
                                : 'none',
                        transition: 'all 0.2s ease'
                    }}>
                        {/* Controls - ONLY show/enable when this tab is active */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
                            {isEditMode && ( // Combine button only in edit mode
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    startIcon={<MergeTypeIcon />}
                                    disabled={!canMergeSelections()} // Disable based on selection
                                    onClick={handleOpenCombineDialog}
                                    sx={{ 
                                        mr: 1,
                                        borderRadius: 2,
                                        textTransform: 'none'
                                    }}
                                >
                                    Combine
                                </Button>
                            )}
                            {/* Edit/Save button always visible in this tab, but appearance/action changes */}
                            <Button
                                variant="contained"
                                size="small"
                                color={isEditMode ? (hasChanges ? "success" : "primary") : "primary"}
                                startIcon={isEditMode ? <SaveIcon /> : <EditIcon />}
                                onClick={toggleEditMode}
                                sx={{ 
                                    borderRadius: 2,
                                    textTransform: 'none'
                                }}
                            >
                                {isEditMode ? "Save INCI Changes" : "Edit INCI List"}
                            </Button>
                        </Box>

                        {/* Warning message - ONLY show when this tab is active and relevant */}
                        {hasChanges && !isEditMode && (
                            <Box sx={{
                                backgroundColor: mode === 'dark' ? 'rgba(255, 159, 10, 0.15)' : 'rgba(255, 149, 0, 0.08)',
                                border: `1px solid ${theme.palette.warning.main}`,
                                borderRadius: 2,
                                p: 2,
                                mb: 3,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <WarningAmberIcon color="warning" sx={{ mr: 2 }} />
                                <Typography
                                    color="text.primary"
                                    variant="body2"
                                    fontWeight="500"
                                >
                                    Unsaved INCI changes! Click "Edit INCI List" then "Save INCI Changes" to stage them, or recalculate to discard. Remember to save the whole formula afterwards.
                                </Typography>
                            </Box>
                        )}

                        {/* The actual Detailed List */}
                        <List 
                            dense 
                            sx={{ 
                                borderRadius: 2,
                                border: `1px solid ${theme.palette.divider}`,
                                bgcolor: 'background.paper',
                                '& .MuiListItem-root': {
                                    borderRadius: 1,
                                    mx: 0.5
                                }
                            }}
                        >
                            {list.map((item, index) => (
                                <React.Fragment key={item.inciName + '-' + index}>
                                    <ListItem 
                                        dense 
                                        sx={{ 
                                            py: 1,
                                            bgcolor: item.isCombined 
                                                ? (mode === 'dark' ? 'rgba(10, 132, 255, 0.15)' : 'rgba(0, 122, 255, 0.08)') 
                                                : 'transparent', 
                                            '&:hover': { 
                                                bgcolor: item.isCombined 
                                                    ? (mode === 'dark' ? 'rgba(10, 132, 255, 0.25)' : 'rgba(0, 122, 255, 0.12)') 
                                                    : (mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)') 
                                            },
                                            transition: 'background-color 0.15s ease'
                                        }}
                                    >
                                        <ListItemText
                                            primary={`${item.inciName || item.inci_name} - ${item.concentration}%`}
                                            secondary={item.isCombined ? '(Combined)' : null}
                                            primaryTypographyProps={{ 
                                                variant: 'body2',
                                                fontWeight: item.isCombined ? 600 : 400
                                            }}
                                            secondaryTypographyProps={{ 
                                                variant: 'caption',
                                                sx: { 
                                                    color: mode === 'dark' ? 'primary.main' : 'primary.dark'
                                                }
                                            }}
                                        />
                                        {/* Edit controls inside list item - ONLY show in edit mode */}
                                        {isEditMode && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                                <Tooltip title="Remove ingredient from INCI list">
                                                    <IconButton 
                                                        edge="end" 
                                                        size="small" 
                                                        color="error" 
                                                        onClick={(e) => { 
                                                            e.stopPropagation(); 
                                                            handleOpenRemoveDialog(index); 
                                                        }} 
                                                        sx={{ 
                                                            mr: 0.5,
                                                            '&:hover': {
                                                                bgcolor: mode === 'dark' ? 'rgba(255, 69, 58, 0.15)' : 'rgba(255, 59, 48, 0.08)'
                                                            }
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Checkbox 
                                                    edge="end" 
                                                    size="small" 
                                                    onChange={() => handleSelectItem(index)} 
                                                    checked={selectedItems.includes(index)}
                                                    sx={{ 
                                                        color: mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.25)',
                                                        '&.Mui-checked': {
                                                            color: 'primary.main'
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </ListItem>
                                    {/* Show original ingredients */}
                                    {item.isCombined && item.originalIngredients && (
                                        <Box sx={{ 
                                            pl: 4, 
                                            pr: 2, 
                                            py: 1, 
                                            mx: 2,
                                            my: 0.5,
                                            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)', 
                                            borderRadius: 1.5,
                                            border: `1px dashed ${theme.palette.divider}`
                                        }}>
                                            <Typography variant="caption" sx={{ display: 'block', mb: 0.5, fontWeight: 'bold', color: 'text.secondary' }}>
                                                Original Ingredients:
                                            </Typography>
                                            <List dense disablePadding>
                                                {item.originalIngredients.map((original, origIdx) => (
                                                    <ListItem key={origIdx} dense disablePadding sx={{ py: 0.2 }}>
                                                        <ListItemText
                                                            primary={`${original.inciName || original.inci_name} - ${original.concentration}%`}
                                                            primaryTypographyProps={{ 
                                                                variant: 'caption',
                                                                color: 'text.secondary'
                                                            }}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Box>
                                    )}
                                    {/* Divider */}
                                    {index < list.length - 1 && !(item.isCombined && item.originalIngredients) && (
                                        <Divider component="li" sx={{ my: 0 }} />
                                    )}
                                </React.Fragment>
                            ))}
                        </List>
                    </Box>
                </TabPanel>

                {/* --- Tab Panel 1: Final List View --- */}
                <TabPanel value={currentTab} index={1}>
                    <Box sx={{ 
                        p: 3, 
                        border: `1px solid ${theme.palette.divider}`, 
                        borderRadius: 2,
                        backgroundColor: 'background.paper'
                    }}>
                        <Typography variant="body1" sx={{ lineHeight: 1.7, wordWrap: 'break-word' }}>
                            {finalInciString}
                        </Typography>
                    </Box>
                </TabPanel>

                {/* --- Dialogs remain outside TabPanels but inside the main return --- */}
                {/* Combine Dialog */}
                <Dialog 
                    open={isCombineDialogOpen} 
                    onClose={() => setIsCombineDialogOpen(false)}
                    PaperProps={{
                        elevation: 3,
                        sx: { 
                            borderRadius: 3,
                            overflow: 'hidden'
                        }
                    }}
                >
                    <DialogTitle 
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            backgroundColor: mode === 'dark' ? 'rgba(255, 159, 10, 0.15)' : 'rgba(255, 149, 0, 0.08)',
                            borderBottom: `1px solid ${theme.palette.divider}`
                        }}
                    >
                        <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
                        Confirm Combination
                    </DialogTitle>
                    <DialogContent sx={{ p: 3, mt: 1 }}>
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                            Are you sure you want to combine the following ingredients into one entry?
                        </Typography>
                        <List 
                            dense 
                            sx={{ 
                                my: 1, 
                                backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)', 
                                borderRadius: 2, 
                                p: 1,
                                border: `1px solid ${theme.palette.divider}`
                            }}
                        >
                            {selectedItems.map((index) => (
                                list[index] && // Check if item exists
                                <ListItem key={index} dense>
                                    <ListItemText 
                                        primary={`${list[index].inciName || list[index].inci_name} - ${list[index].concentration}%`}
                                        primaryTypographyProps={{
                                            fontWeight: 500
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2" color="text.secondary">
                            The combined entry will use the name of the first selected ingredient ({list[Math.min(...selectedItems)]?.inciName || list[Math.min(...selectedItems)]?.inci_name || 'N/A'}) and sum the concentrations. This action modifies the INCI list locally.
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ p: 2, pt: 0 }}>
                        <Button 
                            onClick={() => setIsCombineDialogOpen(false)}
                            sx={{ 
                                borderRadius: 2,
                                textTransform: 'none'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleCombineIngredients} 
                            color="primary" 
                            variant="contained" 
                            startIcon={<MergeTypeIcon />}
                            sx={{ 
                                borderRadius: 2,
                                textTransform: 'none',
                                boxShadow: 'none'
                            }}
                        >
                            Combine
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Remove Confirmation Dialog */}
                <ConfirmationDialog
                    open={isRemoveDialogOpen}
                    onClose={() => setIsRemoveDialogOpen(false)}
                    onConfirm={handleRemoveItem}
                    title="Confirm Removal"
                    message="Are you sure you want to remove the following ingredient from the INCI list?"
                    highlightedItem={
                        itemToRemove !== null && list[itemToRemove] 
                        ? `${list[itemToRemove].inciName || list[itemToRemove].inci_name} - ${list[itemToRemove].concentration}%${list[itemToRemove].isCombined ? ' (Combined)' : ''}`
                        : "Unknown ingredient"
                    }
                    details="This action modifies the INCI list locally."
                    confirmButtonText="Remove"
                    confirmButtonColor="error"
                    confirmButtonIcon={<DeleteIcon />}
                />
            </CardContent>
        </Card>
    );
};

export default InciNamesList;