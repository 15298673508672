import React, { useContext } from 'react';
import { TextField, IconButton, Box, alpha } from '@mui/material';
import { ArrowUpward, ArrowDownward, Delete } from '@mui/icons-material';
import { ThemeContext } from '../../contexts/ThemeContext';
import { IngredientsToolbar, IngredientsFooter } from './IngredientsToolbar';
import { DataTable } from '../common';

const IngredientsGrid = ({
  treeData,
  columns,
  renderKey,
  totalConcentration,
  getTreeDataPath,
  handleConcentrationBlur,
  concentrations,
  moveRow,
  selectedIngredients,
  calculateEndFormulaConcentration,
  onExportToExcel,
  onGeneratePublicUrl,
  onRemoveIngredient
}) => {
  const { mode } = useContext(ThemeContext);
  // Column configurations
  const allColumns = [
    {
      field: "order",
      headerName: "Order",
      flex: 1,
      sortable: false,
      filterable: false,
    },
    { field: "sap_code", headerName: "SAP Code", flex: 1 },
    { field: "trade_name", headerName: "Trade Name", flex: 1 },
    { field: "inci_name", headerName: "INCI Name", flex: 1 },
    { field: "einecs_no", headerName: "EINECS NO", flex: 1 },
    { field: "cas_no", headerName: "CAS No", flex: 1 },
    { field: "percent_active", headerName: "% Active", flex: 1 },
    { field: "manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "country_of_origin", headerName: "Country of Origin", flex: 1 },
    { field: "reach", headerName: "REACH", flex: 1 },
    { field: "function", headerName: "Functions", flex: 1 },
    { field: "restrictions", headerName: "Restrictions", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "vegan", headerName: "Vegan", flex: 1 },
    {
      field: "iso_16128_natural_origin_ingredient",
      headerName: "ISO 16128 Natural Origin Ingredient",
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier", flex: 1 },
    {
      field: "no_animal_testing_declaration",
      headerName: "No Animal Testing Declaration",
      flex: 1,
    },
    {
      field: "sds_validity_3_years",
      headerName: "SDS (Validity 3 Years)",
      flex: 1,
    },
    { field: "organic", headerName: "Organic", flex: 1 },
    {
      field: "cosmos_certification",
      headerName: "COSMOS Certification",
      flex: 1,
    },
    { field: "gluten_free", headerName: "Gluten Free", flex: 1 },
    { field: "gmo", headerName: "GMO", flex: 1 },
    { field: "microplastic", headerName: "Microplastic", flex: 1 },
    { field: "rspo", headerName: "RSPO", flex: 1 },
    { field: "rspo_no", headerName: "RSPO №", flex: 1 },
    { field: "fairtrade", headerName: "Fairtrade", flex: 1 },
    {
      field: "origin_of_raw_material",
      headerName: "Origin of Raw Material",
      flex: 1,
    },
    {
      field: "microbiological_analysis",
      headerName: "Microbiological Analysis",
      flex: 1,
    },
    { field: "solubility", headerName: "Solubility", flex: 1 },
    {
      field: "concentration",
      headerName: "Concentration on formula in %",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return (
            <span style={{ fontWeight: "bold" }}>
              {params.value.toFixed(2)}%
            </span>
          );
        }
        if (params.row.parentId) {
          return <span>{params.value.toFixed(2)}%</span>;
        }
        return (
          <TextField
            variant="outlined"
            size="small"
            key={`${params.row.id}-${concentrations[params.row.id]?.concentration || ''}`}
            defaultValue={concentrations[params.row.id]?.concentration || ""}
            onBlur={(e) => handleConcentrationBlur(params.row.id, e)}
          />
        );
      },
    },
    {
      field: "concentrationInEndFormula",
      headerName: "Concentration in end formula",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return null;
        }
        const endFormulaConcentration = calculateEndFormulaConcentration(
          params.row
        );
        return <span>{endFormulaConcentration}%</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.row.isSummary || params.row.parentId) {
          return null;
        }

        const index = selectedIngredients.findIndex(
          (ingredient) => `ingredient-${ingredient.id}` === params.row.id
        );

        const originalId = params.row.id.startsWith('ingredient-')
            ? parseInt(params.row.id.split('-')[1], 10)
            : null;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {index > 0 && (
              <IconButton
                aria-label="move up"
                size="small"
                onClick={() => moveRow(index, index - 1)}
              >
                <ArrowUpward fontSize="inherit" />
              </IconButton>
            )}
            {index < selectedIngredients.length - 1 && (
              <IconButton
                aria-label="move down"
                size="small"
                onClick={() => moveRow(index, index + 1)}
              >
                <ArrowDownward fontSize="inherit" />
              </IconButton>
            )}
            {originalId !== null && ( 
                <IconButton
                    aria-label="remove ingredient"
                    size="small"
                    onClick={() => onRemoveIngredient(originalId)} 
                    color="error" 
                    sx={{ ml: 0.5 }} 
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  // Default columns to display
  const defaultVisibleColumns = [
    "order",
    "sap_code",
    "trade_name",
    "inci_name",
    "einecs_no",
    "cas_no",
    "percent_active",
    "function",
    "price",
    "concentration",
    "concentrationInEndFormula",
    "actions"
  ];

  // Instead of filtering columns, use them all with initialState to set defaults
  const displayColumns = allColumns;

  // Ensure the grid properly handles tree data
  const groupingColDef = {
    headerName: "",
    width: 50,
  };

  // Debug log to make sure subingredients are in the tree data
  const ingredientsCount = treeData.filter(row => !row.parentId && !row.isSummary).length;
  const subingredientsCount = treeData.filter(row => row.parentId).length;
  console.log(`Tree data contains ${ingredientsCount} ingredients and ${subingredientsCount} subingredients`);

  return (
    <Box sx={{ my: 2, mx: 'auto', width: '100%', maxWidth: '100%' }}>
      <DataTable
        key={renderKey}
        rows={treeData}
        columns={displayColumns}
        treeData={true}
        getTreeDataPath={getTreeDataPath}
        disableColumnReorder={false}
        toolbar={() => (
          <IngredientsToolbar 
            onExportToExcel={onExportToExcel} 
            onGeneratePublicUrl={onGeneratePublicUrl} 
          />
        )}
        footer={IngredientsFooter}
        slotProps={{
          footer: { totalConcentration },
        }}
        groupingColDef={groupingColDef}
        autoExpandGroup={true}
        autoHeight
        initialState={{
          treeData: {
            groupsExpanded: true
          },
          columns: {
            columnVisibilityModel: Object.fromEntries(
              allColumns.map(col => [col.field, defaultVisibleColumns.includes(col.field)])
            )
          }
        }}
        sx={{ 
          width: '100%',
          // Passing styles for both card and grid
          card: {
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.08)',
            overflow: 'hidden'
          },
          grid: {
            '& .MuiDataGrid-row': {
              // Add subtle transitions for smoother interactions
              transition: 'background-color 0.2s ease',
            },
            // Style the action buttons for a more Apple-like appearance
            '& .MuiIconButton-root': {
              borderRadius: 1.5,
              p: 0.75,
              '&:hover': {
                backgroundColor: theme => alpha(theme.palette.primary.main, 0.1)
              }
            },
            // Give text fields in the grid a more Apple-like appearance
            '& .MuiTextField-root': {
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
                fontSize: '0.875rem',
                backgroundColor: theme => 
                  theme.palette.mode === 'dark' 
                    ? alpha('#1C1C1E', 0.8) 
                    : alpha('#FFFFFF', 0.8),
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: theme => 
                    theme.palette.mode === 'dark' 
                      ? alpha('#1C1C1E', 0.95) 
                      : alpha('#FFFFFF', 0.95)
                },
                '&.Mui-focused': {
                  boxShadow: theme => 
                    `0 0 0 3px ${alpha(theme.palette.primary.main, 0.25)}`
                }
              }
            }
          }
        }}
      />
    </Box>
  );
};

export default IngredientsGrid;