import React, { useContext } from 'react';
import { Box, Card, CardContent, alpha } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../contexts/ThemeContext';

/**
 * Reusable data table component based on MUI DataGridPro styled with Apple design principles
 * 
 * @param {Object} props - Component props
 * @param {Array} props.rows - Data rows
 * @param {Array} props.columns - Column definitions
 * @param {boolean} props.treeData - Whether to display data as a tree
 * @param {Function} props.getTreeDataPath - Function to get tree data path
 * @param {Object} props.sx - Additional styles
 * @param {Object} props.height - Height of the grid
 * @param {React.ReactNode} props.toolbar - Custom toolbar component
 * @param {React.ReactNode} props.footer - Custom footer component
 * @param {Object} props.slotProps - Props to pass to slots
 * @param {Object} props.groupingColDef - Grouping column definition
 * @param {boolean} props.autoExpandGroup - Whether to auto-expand tree groups
 * @param {Object} props.initialState - Initial state for the grid
 */
const DataTable = ({
  rows = [],
  columns = [],
  treeData = false,
  getTreeDataPath = null,
  sx = {},
  height = "calc(100vh - 100px)",
  toolbar = null,
  footer = null,
  slotProps = {},
  groupingColDef = null,
  autoExpandGroup = false,
  initialState = {},
  ...rest
}) => {
  const { mode } = useContext(ThemeContext);
  
  // Create Apple-styled columns with proper styling
  const enhancedColumns = columns.map(column => ({
    ...column,
    // Add some standard styling to make columns more Apple-like
    headerAlign: column.headerAlign || 'left',
    align: column.align || 'left',
    flex: column.flex || 1,
    minWidth: column.minWidth || 120,
    // If the column has a custom renderCell, keep it, otherwise add styling to the default cell
    renderCell: column.renderCell || undefined 
  }));

  return (
    <Card 
      elevation={1} 
      sx={{ 
        width: "100%", 
        borderRadius: 3,
        overflow: 'hidden',
        border: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'}`,
        backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
        transition: 'all 0.2s ease',
        ...sx.card
      }}
    >
      <CardContent 
        sx={{ 
          p: 0,
          '&:last-child': { 
            pb: 0 
          }
        }}
      >
        <DataGridPro
          rows={rows}
          columns={enhancedColumns}
          treeData={treeData}
          getTreeDataPath={getTreeDataPath}
          slots={{
            toolbar: toolbar,
            footer: footer,
          }}
          slotProps={slotProps}
          groupingColDef={groupingColDef}
          autoGroupingExpand={autoExpandGroup}
          initialState={initialState}
          sx={{ 
            height: height, 
            width: "100%",
            border: 'none',
            // Apple-inspired data grid styling
            '& .MuiDataGrid-main': {
              // Remove ugly outlines and borders
              outline: 'none',
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.04)' : 'rgba(0, 0, 0, 0.02)',
              borderBottom: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'}`,
              // Add subtle shadow for depth
              boxShadow: mode === 'dark' 
                ? '0 1px 3px rgba(0, 0, 0, 0.3)' 
                : '0 1px 3px rgba(0, 0, 0, 0.05)',
            },
            '& .MuiDataGrid-columnHeader': {
              // Apple-style header styling
              padding: '12px 16px',
              fontWeight: 600,
              color: mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)',
              '&:focus': {
                outline: 'none',
              },
              '&:focus-within': {
                outline: 'none',
              },
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none', // Apple doesn't use column separators
            },
            '& .MuiDataGrid-cell': {
              // Apple-style cell styling
              padding: '12px 16px',
              borderBottom: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)'}`,
              fontSize: '0.875rem',
              fontWeight: 400,
              color: mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.85)',
              '&:focus': {
                outline: 'none',
              },
              '&:focus-within': {
                outline: 'none',
              },
            },
            '& .MuiDataGrid-row': {
              // Add hover effect like in macOS tables
              '&:hover': {
                backgroundColor: mode === 'dark' 
                  ? alpha('#FFFFFF', 0.04) 
                  : alpha('#000000', 0.02),
              },
              '&.Mui-selected': {
                backgroundColor: mode === 'dark' 
                  ? alpha('#0A84FF', 0.2) 
                  : alpha('#007AFF', 0.1),
                '&:hover': {
                  backgroundColor: mode === 'dark' 
                    ? alpha('#0A84FF', 0.25) 
                    : alpha('#007AFF', 0.15),
                },
              },
            },
            // Handle parent rows in tree data
            '& .MuiDataGrid-row--firstParent': {
              fontWeight: 500,
              backgroundColor: mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.03)' 
                : 'rgba(0, 0, 0, 0.015)',
            },
            // Style group cell style (for tree data)
            '& .MuiDataGrid-cellCheckbox': {
              padding: '0 8px',
            },
            // Scrollbars like macOS
            '& ::-webkit-scrollbar': {
              width: '8px',
              height: '8px',
            },
            '& ::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.25)',
              },
            },
            // Footer styling
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.05)'}`,
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
            },
            // Toolbar styling
            '& .MuiDataGrid-toolbarContainer': {
              padding: '8px 16px',
              backgroundColor: mode === 'dark' ? 'transparent' : 'transparent',
              borderBottom: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)'}`,
            },
            // Empty overlay - Apple style
            '& .MuiDataGrid-overlay': {
              backgroundColor: 'transparent',
              color: mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.4)',
              fontSize: '0.9rem',
              fontWeight: 500,
            },
            // When editing - nice Apple style focus border
            '& .MuiDataGrid-cell--editing': {
              boxShadow: `0 0 0 2px ${mode === 'dark' ? '#0A84FF' : '#007AFF'}`,
              borderRadius: '4px',
              backgroundColor: mode === 'dark' ? '#1C1C1E' : '#FFFFFF',
            },
            // Apply any additional grid styles passed in
            ...sx.grid 
          }}
          {...rest}
        />
      </CardContent>
    </Card>
  );
};

DataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  treeData: PropTypes.bool,
  getTreeDataPath: PropTypes.func,
  sx: PropTypes.object,
  height: PropTypes.string,
  toolbar: PropTypes.any,
  footer: PropTypes.any,
  slotProps: PropTypes.object,
  groupingColDef: PropTypes.object,
  autoExpandGroup: PropTypes.bool,
  initialState: PropTypes.object
};

export default DataTable;