// FolderModule.jsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import AuthService from "../services/auth.service";
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});

const FolderModule = ({
  open,
  onClose,
  folders,
  onSave,
  refreshFolders,
  actionType, // Ensure this prop is received
  initialFormulaName,
}) => {
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [folderName, setFolderName] = useState('');
  const [formulaName, setFormulaName] = useState(initialFormulaName || '');

  useEffect(() => {
    if (actionType === 'saveAsNew') {
      setFormulaName('');
      setFolderName('');
    } else {
      setFormulaName(initialFormulaName || '');
    }
  }, [actionType, initialFormulaName]);

  const handleSave = async () => {
    console.log("FolderModule handleSave called with actionType =", actionType);
    
    let finalFolderId = selectedFolderId;

    // If saving as new and a new folder name is provided, create the folder first
    if (folderName.trim() !== '') {
      try {
        const response = await api.post("/api/folders", { name: folderName }, {
          headers: {
            "x-access-token": AuthService.getCurrentUser().accessToken,
          },
        });
        finalFolderId = response.data.id;
        refreshFolders(); // Refresh folder list
      } catch (error) {
        console.error("Error creating folder:", error);
        alert("Failed to create folder.");
        return;
      }
    }

    // Validation
    if (!finalFolderId) {
      alert("Please select or create a folder.");
      return;
    }

    if (!formulaName.trim()) {
      alert("Please enter a formula name.");
      return;
    }

    // Call the onSave prop with the folder ID and formula name
    onSave(finalFolderId, formulaName);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          padding: 4,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {actionType === 'saveAsNew' ? 'Save as New Formula' : 'Update Formula'}
        </Typography>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="folder-select-label">Select Folder</InputLabel>
          <Select
            labelId="folder-select-label"
            value={selectedFolderId}
            label="Select Folder"
            onChange={(e) => setSelectedFolderId(e.target.value)}
            disabled={actionType === 'saveAsNew' && folderName.trim() !== ''}
          >
            {folders.map((folder) => (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {actionType === 'saveAsNew' && (
          <TextField
            fullWidth
            label="New Folder Name"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            sx={{ mt: 2 }}
            required
          />
        )}

        <TextField
          fullWidth
          label="Formula Name"
          value={formulaName}
          onChange={(e) => setFormulaName(e.target.value)}
          sx={{ mt: 2 }}
          required
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {actionType === 'saveAsNew' ? 'Save as New' : 'Update'}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default FolderModule;
