import React from 'react';
import {
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

/**
 * A reusable confirmation dialog component
 * @param {Object} props
 * @param {boolean} props.open - Whether the dialog is open
 * @param {Function} props.onClose - Function to call when dialog is closed without confirmation
 * @param {Function} props.onConfirm - Function to call when action is confirmed
 * @param {string} props.title - Dialog title
 * @param {string} props.message - Main confirmation message
 * @param {string} [props.details] - Optional secondary details
 * @param {string} [props.highlightedItem] - Item to highlight in a gray box
 * @param {string} [props.confirmButtonText="Confirm"] - Text for confirm button
 * @param {string} [props.confirmButtonColor="primary"] - Color for confirm button
 * @param {React.ReactNode} [props.confirmButtonIcon] - Icon for confirm button
 * @param {string} [props.cancelButtonText="Cancel"] - Text for cancel button
 */
const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  details,
  highlightedItem,
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'primary',
  confirmButtonIcon,
  cancelButtonText = 'Cancel'
}) => {
  // Determine header styling based on button color
  const getHeaderStyle = () => {
    if (confirmButtonColor === 'error') {
      return {
        display: 'flex', 
        alignItems: 'center',
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 69, 58, 0.15)' : 'rgba(255, 59, 48, 0.08)',
        borderBottom: theme => `1px solid ${theme.palette.divider}`
      };
    } else if (confirmButtonColor === 'warning') {
      return {
        display: 'flex', 
        alignItems: 'center',
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 159, 10, 0.15)' : 'rgba(255, 149, 0, 0.08)',
        borderBottom: theme => `1px solid ${theme.palette.divider}`
      };
    }
    return { display: 'flex', alignItems: 'center' };
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={getHeaderStyle()}>
        <WarningAmberIcon color={confirmButtonColor === 'error' ? 'error' : 'warning'} sx={{ mr: 1 }} />
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', mt: 1 }}>
          {message}
        </Typography>
        
        {/* Highlighted item */}
        {highlightedItem && (
          <Typography variant="body1" sx={{ 
            my: 2, 
            p: 1, 
            backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#f5f5f5', 
            borderRadius: '4px', 
            display: 'inline-block',
            color: 'text.primary'
          }}>
            {highlightedItem}
          </Typography>
        )}
        
        {/* Additional details */}
        {details && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {details}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {cancelButtonText}
        </Button>
        <Button 
          onClick={onConfirm} 
          color={confirmButtonColor} 
          variant="contained"
          startIcon={confirmButtonIcon}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;