import React, { useState, useEffect, useContext, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AuthProvider, AuthContext } from "../src/contexts/AuthContext"
import { ThemeProviderWrapper } from "../src/contexts/ThemeContext"
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import IngredientsList from "./components/IngredientsList";
import AddIngredient from './components/AddIngredient';
import UploadIngredients from "./components/UploadIngredients";
import PublicFormulaView from './components/PublicFormulaView';
import IngredientSearchEdit from './components/IngredientSearchEdit';
import Sidebar from './components/Sidebar';
import { Container, Box, Toolbar, CircularProgress, useTheme } from '@mui/material';


function IngredientsListWrapper({ isEditing, editingFormulaId, setIsEditing, setEditingFormulaId, sidebarOpen, refreshSidebar }) {
  const location = useLocation();
  const componentKey = `${location.pathname}-${location.search}-${location.state?.isNewFormula ? 'new' : 'existing'}-${editingFormulaId || 'none'}-${location.key || Date.now()}`;
  
  return (
    <IngredientsList
      key={componentKey}
      isEditing={isEditing}
      editingFormulaId={editingFormulaId}
      setIsEditing={setIsEditing}
      setEditingFormulaId={setEditingFormulaId}
      sidebarOpen={sidebarOpen}
      refreshSidebar={refreshSidebar}
    />
  );
}

const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useContext(AuthContext);

  if (loading) {
    return (
       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
         <CircularProgress />
       </Box>
    );
  }

  return currentUser ? children : <Navigate to="/login" />;
};

const drawerWidth = 280;

const AppContent = () => {
  const { currentUser, loading } = useContext(AuthContext);
  const [folders, setFolders] = useState([]);
  const [editingFormulaId, setEditingFormulaId] = useState(null); 
  const [isEditing, setIsEditing] = useState(false); 
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarRefreshCounter, setSidebarRefreshCounter] = useState(0);
  const theme = useTheme();
  const location = useLocation();
  
  // Check if the current path is a public path that doesn't need the sidebar layout
  const isPublicRoute = ['/login', '/register'].some(route => 
    location.pathname === route || location.pathname.startsWith(route + '/')
  );
  
  // Special case for the public formula view which has its own layout
  const isPublicFormulaView = location.pathname.startsWith('/formulas/view/');

  // Create a function to refresh the sidebar
  const refreshSidebar = () => {
    setSidebarRefreshCounter(prev => prev + 1);
  };

  // Handle edit formula logic and pass it to IngredientsList
  const handleEditFormula = (formulaId) => {
    setIsEditing(true);
    setEditingFormulaId(formulaId);
  };

  const resetEditingState = useCallback(() => {
    console.log("AppContent: Resetting editing state (isEditing=false, editingFormulaId=null)");
    setIsEditing(false);
    setEditingFormulaId(null);
  }, []);

  const handleSidebarToggle = () => {
    setSidebarOpen(prevState => !prevState);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
   );
 }

  return (
    <Container maxWidth="xl" disableGutters>
      {currentUser && !isPublicRoute && !isPublicFormulaView && (
        <Sidebar
            selectFormula={handleEditFormula}
            editFormula={handleEditFormula}
            folders={folders}
            isOpen={sidebarOpen}
            onToggle={handleSidebarToggle}
            refreshCounter={sidebarRefreshCounter}
          />
      )}
            <Box
        component="main"
        sx={{
          padding: isPublicRoute ? 0 : (isPublicFormulaView ? '24px' : '24px'), 
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: currentUser && !isPublicRoute && !isPublicFormulaView && sidebarOpen ? `${drawerWidth}px` : 0,
          width: currentUser && !isPublicRoute && !isPublicFormulaView && sidebarOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
          maxWidth: '100%',
          height: isPublicRoute ? '100vh' : 'auto',
          display: isPublicRoute ? 'flex' : 'block',
          justifyContent: isPublicRoute ? 'center' : 'flex-start',
          alignItems: isPublicRoute ? 'center' : 'flex-start',
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={currentUser ? <Navigate to="/home" /> : <Login />} />
          <Route path="/register" element={currentUser ? <Navigate to="/home" /> : <Register />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home resetEditingState={resetEditingState}/>
              </PrivateRoute>
            }
          />
          <Route
            path="/ingredients"
            element={
              <PrivateRoute>
                <IngredientsListWrapper
                  isEditing={isEditing}
                  editingFormulaId={editingFormulaId}
                  setIsEditing={setIsEditing}
                  setEditingFormulaId={setEditingFormulaId}
                  sidebarOpen={sidebarOpen}
                  refreshSidebar={refreshSidebar}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/addingredient"
            element={
              <PrivateRoute>
                <AddIngredient />
              </PrivateRoute>
            }
          />
          <Route
            path="/uploadingredients"
            element={
              <PrivateRoute>
                <UploadIngredients refreshIngredients={() => {}} />
              </PrivateRoute>
            }
          />
          <Route
          path="/editingredients"
          element={
            <PrivateRoute>
              <IngredientSearchEdit />
            </PrivateRoute>
          }
          />
          <Route path="/formulas/view/:publicUrl" element={<PublicFormulaView />} />
        </Routes>
      </Box>
    </Container>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProviderWrapper>
          <AppContent />
        </ThemeProviderWrapper>
      </AuthProvider>
    </Router>
  );
};

export default App;