import React, { useEffect, useState, useContext } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Collapse,
  Box,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  ListItemButton
} from "@mui/material";
import {
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  Delete as DeleteIcon,
  Edit as EditIcon,
  AccountCircle,
  ChevronRight,
  Folder as FolderIcon,
  Description as DescriptionIcon,
  Home as HomeIcon,
  Add as AddIcon,
  Upload as UploadIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import { ThemeSwitch } from './ThemeToggle';
import ConfirmationDialog from "./common/ConfirmationDialog";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../contexts/AuthContext"
import { ThemeContext } from "../contexts/ThemeContext"
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});

const drawerWidth = 280;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open', // Prevent 'open' prop from reaching DOM
})(({ theme, open }) => ({ // Destructure 'open' prop
  // Original styles
  boxShadow: 'none',
  backdropFilter: 'blur(10px)',
  backgroundColor: theme.palette.background.appBar,
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: theme.palette.text.primary,
  // Add transitions for width and margin
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  zIndex: 1202, // Ensure it's above drawer if needed, adjust based on actual zIndex
  // Apply styles when 'open' is true
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`, // Adjust width
    marginLeft: `${drawerWidth}px`,          // Add margin
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const SidebarMenuItem = styled(ListItem)(({ theme }) => ({
  marginBottom: 4,
  padding: '4px 8px',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const Sidebar = ({ selectFormula, openModal, editFormula, isOpen, onToggle, refreshCounter, children }) => {
  const [folders, setFolders] = useState([]);
  const [expandedFolderId, setExpandedFolderId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  const { mode } = useContext(ThemeContext);

  const fetchFolders = async () => {
    try {
      console.log("Sidebar: Fetching folders...");
      const response = await api.get("/api/folders", {
        headers: { "x-access-token": currentUser.accessToken },
      });
      setFolders(response.data);
      console.log("Sidebar: Folders updated");
    } catch (error) {
      console.error("Error fetching folders", error);
    }
  };

  // Initial load
  useEffect(() => {
    if (currentUser) {
      fetchFolders();
    }
  }, [currentUser]);
  
  // Refresh when counter changes
  useEffect(() => {
    if (currentUser && refreshCounter > 0) {
      console.log("Sidebar: Refresh triggered", refreshCounter);
      fetchFolders();
    }
  }, [refreshCounter, currentUser]);

  const handleDrawerToggle = () => {
    onToggle();  // Call the onToggle function passed as a prop
  };

  const handleFolderClick = (folderId) => {
    setExpandedFolderId(expandedFolderId === folderId ? null : folderId);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [formulaToDelete, setFormulaToDelete] = useState(null);

  const handleDeleteFormula = (formulaId, formulaName) => {
    setFormulaToDelete({ id: formulaId, name: formulaName });
    setIsDeleteDialogOpen(true);
  };
  
  const confirmDeleteFormula = async () => {
    if (!formulaToDelete) return;
    
    try {
      await api.delete(`/api/formulas/${formulaToDelete.id}`, {
        headers: {
          "x-access-token": currentUser.accessToken,
        },
      });
      fetchFolders();
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting formula", error);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleEditFormula = (formulaId) => {
    // Navigate first with state, then edit the formula
    navigate("/ingredients", { 
      state: { 
        isNewFormula: false,
        timestamp: Date.now() // Add timestamp to force state change detection
      },
      replace: true // Replace history to ensure clean state
    });
    editFormula(formulaId);
  };

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <StyledAppBar position="fixed" open={isOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
            component={Link}
            to="/home"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <img 
              src={mode === 'dark' ? "/Inci_logo_only-white.webp" : "/Inci_logo_only-black.webp"} 
              alt="INCI Navigator Logo" 
              style={{ 
                width: "28px", 
                height: "auto", 
                marginRight: "12px" 
              }} 
            />
            <Typography
              variant="h6"
              noWrap
              sx={{ 
                fontWeight: 600,
                textDecoration: "none", 
                color: "inherit",
                letterSpacing: '-0.5px'
              }}
            >
              INCI Navigator
            </Typography>
          </Box>
          <div style={{ flexGrow: 1 }} />
          
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <ThemeSwitch />
          </Box>
        
          <IconButton
            color="primary"
            onClick={handleUserMenuOpen}
            edge="end"
            style={{ marginLeft: "16px" }} 
          >
            <Avatar sx={{ 
              width: 32, 
              height: 32, 
              backgroundColor: 'primary.main',
              fontSize: '0.9rem',
              fontWeight: 500
            }}>
              {currentUser?.username?.charAt(0).toUpperCase() || "U"}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
            PaperProps={{
              elevation: 1,
              sx: {
                borderRadius: 2,
                mt: 0.5,
                boxShadow: mode === 'dark' 
                  ? '0px 4px 20px rgba(0, 0, 0, 0.4)' 
                  : '0px 4px 20px rgba(0, 0, 0, 0.1)'
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleLogout} sx={{ px: 2, py: 1 }}>
              <ListItemIcon>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5H11C11.6 5 12 4.6 12 4C12 3.4 11.6 3 11 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11C11.6 21 12 20.6 12 20C12 19.4 11.6 19 11 19H5V5Z" fill="currentColor"/>
                  <path d="M21.7 11.3L17.7 7.3C17.3 6.9 16.7 6.9 16.3 7.3C15.9 7.7 15.9 8.3 16.3 8.7L18.6 11H9C8.4 11 8 11.4 8 12C8 12.6 8.4 13 9 13H18.6L16.3 15.3C15.9 15.7 15.9 16.3 16.3 16.7C16.5 16.9 16.7 17 17 17C17.3 17 17.5 16.9 17.7 16.7L21.7 12.7C22.1 12.3 22.1 11.7 21.7 11.3Z" fill="currentColor"/>
                </svg>
              </ListItemIcon>
              <Typography>Logout</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </StyledAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'background.paper',
            color: 'text.primary',
            borderRight: theme => `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
            padding: 2
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <Toolbar />
        
        <List sx={{ mb: 2 }}>
          <ListItemButton
            component={Link}
            to="/home"
            sx={{
              borderRadius: 2,
              mb: 1,
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ListItemIcon>
              <HomeIcon sx={{ color: 'primary.main' }} />
            </ListItemIcon>
            <ListItemText primary="Home" primaryTypographyProps={{ fontWeight: 500 }} />
          </ListItemButton>
          
          <ListItemButton
            onClick={() => {
              // Force reset of formula editing state
              if (typeof editFormula === 'function') {
                editFormula(null); // Pass null to clear editing state
              }
              
              // Navigate with clear new formula flag
              navigate("/ingredients", { 
                state: { 
                  isNewFormula: true,
                  forceReset: true,
                  timestamp: Date.now() 
                },
                replace: true
              });
            }}
            sx={{
              borderRadius: 2,
              mb: 1,
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ListItemIcon>
              <AddIcon sx={{ color: 'secondary.main' }} />
            </ListItemIcon>
            <ListItemText primary="New Formula" primaryTypographyProps={{ fontWeight: 500 }} />
          </ListItemButton>
          
          <ListItemButton
            component={Link}
            to="/addingredient"
            sx={{
              borderRadius: 2,
              mb: 1,
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ListItemIcon>
              <AddIcon sx={{ color: 'info.main' }} />
            </ListItemIcon>
            <ListItemText primary="Add Ingredients" primaryTypographyProps={{ fontWeight: 500 }} />
          </ListItemButton>
          
          <ListItemButton
            component={Link}
            to="/uploadingredients"
            sx={{
              borderRadius: 2,
              mb: 1,
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <ListItemIcon>
              <UploadIcon sx={{ color: 'warning.main' }} />
            </ListItemIcon>
            <ListItemText primary="Upload Ingredients" primaryTypographyProps={{ fontWeight: 500 }} />
          </ListItemButton>
        </List>
        
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="subtitle2" fontWeight="600" color="text.secondary" sx={{ px: 1, mb: 1.5 }}>
          SAVED FORMULAS
        </Typography>
        
        <div style={{ overflow: "auto" }}>
          <List>
            {folders.map((folder) => (
              <div key={folder.id}>
                <ListItemButton 
                  onClick={() => handleFolderClick(folder.id)}
                  sx={{
                    borderRadius: 2,
                    mb: 0.5,
                    py: 0.75,
                    '&:hover': { bgcolor: 'action.hover' }
                  }}
                >
                  <ListItemIcon>
                    <FolderIcon sx={{ color: 'primary.main', opacity: 0.8 }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={folder.name} 
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                  {expandedFolderId === folder.id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={expandedFolderId === folder.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding sx={{ pl: 2 }}>
                    {folder.formulas &&
                      folder.formulas.map((formula) => (
                        <ListItemButton 
                          key={formula.id} 
                          sx={{ 
                            borderRadius: 2,
                            pl: 2,
                            py: 0.75,
                            mb: 0.5,
                            '&:hover': { bgcolor: 'action.hover' }
                          }}
                          onClick={() => {
                            navigate("/ingredients", { 
                              state: { 
                                isNewFormula: false,
                                timestamp: Date.now()  
                              },
                              replace: true 
                            });
                            selectFormula(formula.id);
                          }}
                        >
                          <ListItemIcon>
                            <DescriptionIcon sx={{ color: 'text.secondary', opacity: 0.7 }} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={formula.name}
                            primaryTypographyProps={{ 
                              noWrap: true,
                              sx: { maxWidth: '120px' }
                            }}
                          />
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditFormula(formula.id);
                            }}
                            size="small"
                            sx={{ color: 'primary.main', mr: 0.5 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFormula(formula.id, formula.name);
                            }}
                            size="small"
                            sx={{ color: 'error.main' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </ListItemButton>
                      ))}
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
        </div>
      </Drawer>
      <Main open={isOpen}>
        <Toolbar />
        {children}
      </Main>

      {/* Delete Confirmation Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={confirmDeleteFormula}
        title="Delete Formula"
        message="Are you sure you want to delete this formula?"
        highlightedItem={formulaToDelete?.name}
        details="This action cannot be undone."
        confirmButtonText="Delete"
        confirmButtonColor="error"
        confirmButtonIcon={<DeleteIcon />}
      />
    </div>
  );
};

export default Sidebar;