import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Typography,
  Snackbar,
  Button,
  Tooltip,
  IconButton
} from "@mui/material";
import axios from "axios";
import debounce from "lodash.debounce";
import AuthService from "../../services/auth.service";
import FolderModule from "../FolderModule";
import { useTheme } from "@mui/material";
import config from "../../config";
import RefreshIcon from "@mui/icons-material/Refresh";
import Delete from '@mui/icons-material/Delete';

// Import modular components
import IngredientSearch from "./IngredientSearch";
import IngredientsGrid from "./IngredientsGrid";
import FormulaActionButtons from "./FormulaActionButtons";
import InciNamesList from "./InciNamesList";

// Import utilities
import * as IngredientsUtils from "./IngredientsUtils";

const api = axios.create({
  baseURL: config.API_URL,
});

const IngredientsList = ({
  isEditing,
  editingFormulaId,
  setIsEditing,
  setEditingFormulaId,
  sidebarOpen,
  refreshSidebar,
}) => {
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [subIngredientsMap, setSubIngredientsMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [concentrationsMap, setConcentrationsMap] = useState({});
  // Helper function to get current formula's concentrations
  const getFormulaConcentrations = () =>
    concentrationsMap[activeFormulaId] || {};
  const [renderKey, setRenderKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [formulaName, setFormulaName] = useState("");
  const [formulas, setFormulas] = useState([]);
  const [loadingFormulas, setLoadingFormulas] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inciNames, setInciNames] = useState({ flat: [], hierarchical: [] });
  const [inciSource, setInciSource] = useState("initial");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [publicUrl, setPublicUrl] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [actionType, setActionType] = useState(null);

  // Add a state to track the active formula ID
  const [activeFormulaId, setActiveFormulaId] = useState(null);

  // Separate handlers for updating and saving as new
  const handleUpdateFormulaClick = () => {
    if (!isEditing || !editingFormulaId) {
      alert("No formula is being edited.");
      return;
    }
    // Directly call the save/update function
    // It needs the current name and folderId which are in state
    handleSaveOrUpdateFormula(selectedFolder, formulaName, false); // false means not 'saveAsNew'
  };

  const handleSaveAsNewFormulaClick = () => {
    // Open the modal to get name/folder for the *new* formula
    setActionType("saveAsNew");
    // Reset formula name for the dialog if desired, or keep current as default
    // setFormulaName('');
    setOpenModal(true);
  };

  useEffect(() => {
    if (actionType === "saveAsNew") {
      setOpenModal(true);
    }
  }, [actionType]);

  // Function to reset the component state
  const resetState = useCallback(() => {
    console.log("🧹 Thoroughly resetting component state");
    // Clear all ingredient-related state
    setSelectedIngredients([]);
    setFormulaName("");
    setIsEditing(false);
    setEditingFormulaId(null);
    setPublicUrl(null);
    setErrorMessage("");

    // Clean up ALL formula data
    setConcentrationsMap({}); // Clear entire concentration map
    setActiveFormulaId(null); // Reset active formula ID
    setInciNames({ flat: [], hierarchical: [] }); // Reset the INCI names completely
    
    // Force re-render of grid components
    setRenderKey((prevKey) => prevKey + 1);
    
    console.log("🧹 Component state has been reset");
  }, [setIsEditing, setEditingFormulaId]);

  useEffect(() => {
    // Get state parameters from location
    const isNewFormulaAction = location.state?.isNewFormula || false;
    const forceReset = location.state?.forceReset || false;
    const timestamp = location.state?.timestamp || 0;
    
    console.log("🔄 IngredientsList Effect triggered:", { 
      isNewFormulaAction, 
      forceReset,
      timestamp, 
      isEditing, 
      editingFormulaId,
      activeFormulaId 
    });
    
    // CASE 1: New Formula Button was clicked (either initial or with force reset)
    if (isNewFormulaAction || forceReset) {
      console.log("🆕 Creating new formula - COMPLETELY resetting all state");
      
      // IMPORTANT: Call resetState BEFORE setting any new state
      resetState();
      
      // After state is reset, create a new formula context
      setTimeout(() => {
        const tempFormulaId = `new-formula-${Date.now()}`;
        console.log(`🆔 Created new temporary formula ID: ${tempFormulaId}`);
        setActiveFormulaId(tempFormulaId);
        setConcentrationsMap({ [tempFormulaId]: {} }); // Set a completely fresh map
        
        // Clear the location state to prevent repeated resets
        navigate(location.pathname, { replace: true, state: {} });
      }, 0);
      
      return; // Exit early to prevent other logic from running
    } 
    // CASE 2: Loading an existing formula
    else if (isEditing && editingFormulaId) {
       if (activeFormulaId !== editingFormulaId) { 
          console.log(`📂 Loading formula ${editingFormulaId} as it differs from active formula ${activeFormulaId}`);
          handleLoadFormula(editingFormulaId);
       } else {
          console.log(`⚠️ Formula ${editingFormulaId} is already active/loaded.`);
       }
    } 
    // CASE 3: No active formula - initialize a blank one
    else if (!isEditing && !editingFormulaId && !activeFormulaId) {
      console.log("⭐ No active formula - creating a default new formula");
      resetState();
      const tempFormulaId = `new-formula-${Date.now()}`;
      setActiveFormulaId(tempFormulaId);
      setConcentrationsMap({ [tempFormulaId]: {} }); // Set a completely fresh map
    } 
    // CASE 4: Default - no action needed
    else {
      console.log("❔ No action needed, using current formula state");
    }
  
  }, [
    location.state?.isNewFormula, 
    location.state?.forceReset, // Add force reset flag to dependencies
    location.state?.timestamp,
    isEditing, 
    editingFormulaId, 
    resetState, 
    navigate, 
    location.pathname,
    activeFormulaId,
  ]);

  // Fetching all data
  const fetchAllData = useCallback(async () => {
    setLoading(true);
    try {
      const token = AuthService.getCurrentUser()?.accessToken;
      if (!token) {
        throw new Error("No token found");
      }

      const headers = { "x-access-token": token };

      // Fetch Ingredients
      const ingredientResponse = await api.get("/api/ingredients", { headers });
      setIngredients(ingredientResponse.data);

      // Fetch Subingredients
      const subIngredientResponse = await api.get(
        "/api/ingredients/allsubingredients",
        { headers }
      );
      const map = {};
      subIngredientResponse.data.forEach((sub) => {
        if (!map[sub.ingredient_id]) {
          map[sub.ingredient_id] = [];
        }
        map[sub.ingredient_id].push(sub);
      });
      setSubIngredientsMap(map);

      // Fetch Folders
      const folderResponse = await api.get("/api/folders", { headers });
      setFolders(folderResponse.data);

      // Fetch Formulas
      const formulaResponse = await api.get("/api/formulas", { headers });
      setFormulas(formulaResponse.data);

      setLoading(false);

      // IMPORTANT: DO NOT directly call handleLoadFormula here as it creates an infinite loop
      // Instead, return a boolean indicating if we need to reload
      return isEditing && editingFormulaId;
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      return false;
    }
  }, [isEditing, editingFormulaId]);

  // UseEffect for fetching all data
  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  // Load formula for editing
  useEffect(() => {
    if (isEditing && editingFormulaId) {
      handleLoadFormula(editingFormulaId);
    }
  }, [isEditing, editingFormulaId]);

  const handleRemoveIngredient = useCallback((ingredientIdToRemove) => {
    console.log(`Attempting to remove ingredient ID: ${ingredientIdToRemove}`);

    // Filter out the ingredient to remove
    const updatedSelectedIngredients = selectedIngredients.filter(
      (ingredient) => ingredient.id !== ingredientIdToRemove
    );

    if (updatedSelectedIngredients.length < selectedIngredients.length) {
      setSelectedIngredients(updatedSelectedIngredients);

      const gridIngredientId = `ingredient-${ingredientIdToRemove}`;
      setConcentrationsMap((prevMap) => {
        const currentFormulaConcentrations = prevMap[activeFormulaId] || {};
        const updatedFormulaConcentrations = { ...currentFormulaConcentrations };
        if (updatedFormulaConcentrations.hasOwnProperty(gridIngredientId)) {
            delete updatedFormulaConcentrations[gridIngredientId];
            console.log(`Removed concentration for ${gridIngredientId} from formula ${activeFormulaId}`);
        } else {
            console.warn(`Concentration key ${gridIngredientId} not found in map for formula ${activeFormulaId}`);
        }

        return {
          ...prevMap,
          [activeFormulaId]: updatedFormulaConcentrations,
        };
      });

      setSnackbarMessage("Ingredient removed.");
      setSnackbarOpen(true);
      // Trigger recalculation if needed explicitly, otherwise useMemo should handle it
      // handleRecalculateInci();
    } else {
      console.warn(`Ingredient with ID ${ingredientIdToRemove} not found in selectedIngredients.`);
    }
  }, [selectedIngredients, activeFormulaId, setSnackbarMessage, setSnackbarOpen]);


  // Adding Ingredient to table
  const handleAddIngredient = (event, value) => {
    console.log("handleAddIngredient called with value:", value);
    
    // Check for valid input
    if (!value) {
      console.warn("No ingredient selected");
      return;
    }
    
    // Make sure the value has an ID
    if (!value.id) {
      console.error("Selected ingredient has no ID:", value);
      setSnackbarMessage("Error: Selected ingredient has no ID");
      setSnackbarOpen(true);
      return;
    }
    
    // Make sure we have an active formula ID when adding ingredients
    if (!activeFormulaId) {
      console.log(
        "No active formula ID when adding ingredient, creating temporary ID"
      );
      const tempFormulaId = `new-formula-${Date.now()}`;
      setActiveFormulaId(tempFormulaId);

      // Initialize an empty concentrations object for this new formula
      setConcentrationsMap((prev) => ({
        ...prev,
        [tempFormulaId]: {},
      }));
    }

    // Check if ingredient already exists in the formula
    if (selectedIngredients.some((i) => i.id === value.id)) {
      console.log(`Ingredient ${value.id} already in formula, not adding duplicate`);
      setSnackbarMessage("Ingredient already added to formula");
      setSnackbarOpen(true);
      return;
    }
    
    // Add the ingredient
    console.log(
      `Adding ingredient ${value.id} (${value.trade_name || 'unnamed'}) to formula ${activeFormulaId}`
    );
    setSelectedIngredients([...selectedIngredients, value]);
    setSnackbarMessage("Ingredient added to formula");
    setSnackbarOpen(true);
  };

  // Fetching Formulas from DB
  const fetchFormulas = async () => {
    setLoadingFormulas(true);
    try {
      const response = await api.get("/api/formulas", {
        headers: { "x-access-token": AuthService.getCurrentUser().accessToken },
      });
      setFormulas(response.data);
    } catch (error) {
      console.error("Error fetching formulas:", error);
    }
    setLoadingFormulas(false);
  };

  useEffect(() => {
    fetchFormulas();
  }, []);

  // Handle share functionality
  const handleGeneratePublicUrl = async () => {
    if (!editingFormulaId) {
      setSnackbarMessage("No formula selected for sharing.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const token = AuthService.getCurrentUser().accessToken;
      const response = await api.post(
        `/api/formulas/${editingFormulaId}/publicUrl`,
        {},
        {
          headers: { "x-access-token": token },
        }
      );
      setPublicUrl(
        `${window.location.origin}/formulas/view/${response.data.publicUrl}`
      );
      setSnackbarMessage("Public URL generated. Click to copy.");
      setSnackbarOpen(true);
    } catch (err) {
      console.error("Error generating public URL", err);
      setSnackbarMessage("Failed to generate public URL.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Updated method for loading formulas with formula-isolation in concentrations
  const handleLoadFormula = async (formulaId) => {
    console.log(`🔄 Loading formula ${formulaId}...`);
    try {
      setLoadingFormulas(true);
      const response = await api.get(`/api/formulas/${formulaId}`, {
        headers: { "x-access-token": AuthService.getCurrentUser().accessToken },
      });
      console.log("✅ Formula data received:", response.data);

      const formula = response.data; // Use the whole formula object
      const {
        ingredients,
        concentrations: loadedConcentrations,
        inciNames: loadedInciNames,
      } = formula.data || {};

      setActiveFormulaId(formulaId); // Set active formula ID first

      // Deep clone ingredients and concentrations
      const deepClonedIngredients = ingredients
        ? JSON.parse(JSON.stringify(ingredients))
        : [];
      const deepClonedConcentrations = loadedConcentrations
        ? JSON.parse(JSON.stringify(loadedConcentrations))
        : {};

      // Update concentrations map (ensure this is formula-specific if needed, your existing logic seems okay here)
      setConcentrationsMap((prev) => ({
        ...prev,
        [formulaId]: deepClonedConcentrations,
      }));

      setSelectedIngredients(deepClonedIngredients);

      // Rebuild subIngredientsMap from loaded ingredients
      const newSubIngredientsMap = {};
      deepClonedIngredients.forEach((ingredient) => {
        if (ingredient.id) {
          newSubIngredientsMap[ingredient.id] = ingredient.subingredients
            ? JSON.parse(JSON.stringify(ingredient.subingredients))
            : [];
        }
      });
      setSubIngredientsMap(newSubIngredientsMap);

      // --- INCI Names Logic ---
      if (
        loadedInciNames &&
        loadedInciNames.flat &&
        loadedInciNames.flat.length > 0
      ) {
        console.log("📋 Using INCI names loaded from backend.");
        // Deep clone loaded INCI names
        const clonedInci = JSON.parse(JSON.stringify(loadedInciNames));
        setInciNames(clonedInci);
        setInciSource("loaded"); // Mark as loaded from DB
      } else {
        console.log(
          "📋 No INCI names in backend data or empty. Calculating..."
        );
        // Calculate if not present in DB
        const calculatedInci = IngredientsUtils.calculateInciNames(
          deepClonedIngredients,
          deepClonedConcentrations,
          newSubIngredientsMap // Use the map derived from loaded data
        );
        setInciNames(calculatedInci);
        setInciSource("calculated"); // Mark as freshly calculated
      }

      // Set other formula details
      setFormulaName(formula.name || "");
      setSelectedFolder(formula.folderId || "");
      setPublicUrl(
        formula.publicUrl
          ? `${window.location.origin}/formulas/view/${formula.publicUrl}`
          : null
      );
      // Make sure editing state is set correctly if loading implies editing
      setIsEditing(true);
      setEditingFormulaId(formulaId);
    } catch (error) {
      console.error("❌ Error loading formula:", error);
      const errorMessage = error.response?.data?.message || error.message || "Unknown error";
      setSnackbarMessage(`Failed to load formula: ${errorMessage}`);
      setSnackbarOpen(true);
      // Reset state on error?
      resetState();
    } finally {
      setLoadingFormulas(false);
      setRenderKey((prev) => prev + 1); // Force re-render of dependent components
    }
  };

  // Moving ingredients in table
  const moveRow = (fromIndex, toIndex) => {
    const updatedSelectedIngredients = [...selectedIngredients];
    const [movedItem] = updatedSelectedIngredients.splice(fromIndex, 1);
    updatedSelectedIngredients.splice(toIndex, 0, movedItem);

    setSelectedIngredients(updatedSelectedIngredients);
  };

  // Function for total % of Ingredients with real-time updates to backend
  const handleConcentrationBlur = (id, event) => {
    const value = event.target.value;

    // If we don't have an active formula ID, create a temporary one
    if (!activeFormulaId) {
      console.warn(
        "No active formula ID when trying to update concentration, creating temporary ID"
      );
      const tempFormulaId = `new-formula-${Date.now()}`;
      setActiveFormulaId(tempFormulaId);

      // Initialize an empty concentrations object for this new formula
      setConcentrationsMap((prev) => ({
        ...prev,
        [tempFormulaId]: {},
      }));

      // Continue with the temp ID
      console.log(`Created temporary formula ID: ${tempFormulaId}`);
    }

    // At this point we should have an activeFormulaId (either existing or newly created)
    console.log(
      `Updating concentration for ${id} in formula ${activeFormulaId}: ${value}%`
    );

    debouncedHandleConcentrationChange(id, value);

    // If we're editing a formula, send updates individually to the backend
    if (isEditing && editingFormulaId) {
      debouncedUpdateConcentration(id, value);
    }

    // Calculate total after this change
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;

    // Update the formula-specific concentrations
    setConcentrationsMap((prev) => {
      // Get the current formula's concentrations
      const formulaConcentrations = prev[activeFormulaId] || {};

      // Create updated concentrations for this formula
      const updatedConcentrations = {
        ...formulaConcentrations,
        [id]: {
          ...formulaConcentrations[id],
          concentration: numericValue,
        },
      };

      // Calculate total for validation
      const totalConcentration = Object.values(updatedConcentrations).reduce(
        (sum, current) => sum + (current.concentration || 0),
        0
      );

      console.log(
        `Formula ${activeFormulaId} - Updated concentration: ${id} = ${numericValue}%, new total: ${totalConcentration.toFixed(
          2
        )}%`
      );
      // Return updated state with formula isolation
      return {
        ...prev,
        [activeFormulaId]: updatedConcentrations,
      };
    });
  };

  // Send individual concentration updates to the backend
  const debouncedUpdateConcentration = useCallback(
    debounce((id, value) => {
      // Don't update if we're not editing or have no formula ID
      if (!isEditing || !editingFormulaId) return;

      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) return;

      // Create a minimal update object with just the changed concentration
      // Include the formulaId in the key to ensure isolation between formulas
      const updateData = {
        concentrations: {
          // Add formula-specific context to ensure isolation
          [`formula-${editingFormulaId}-${id}`]: {
            concentration: numericValue,
            originalKey: id, // Keep original key for backend reference
          },
        },
      };

      // Log concentration changes - important for debugging the formula isolation issue
      console.log(
        `Formula ${editingFormulaId} - Updated concentration: ${id} = ${numericValue}%`
      );

      api
        .post(`/api/formulas/${editingFormulaId}/concentrations`, updateData, {
          headers: {
            "x-access-token": AuthService.getCurrentUser().accessToken,
          },
        })
        .catch((error) => {
          console.error("Error updating concentration:", error);
        });
    }, 500),
    [isEditing, editingFormulaId]
  );

  // Debounce % for local state updates with formula isolation
  const debouncedHandleConcentrationChange = useCallback(
    debounce((id, value) => {
      if (!activeFormulaId) return;

      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) return;

      setConcentrationsMap((prev) => {
        // Get current formula concentrations or create empty object
        const formulaConcentrations = prev[activeFormulaId] || {};

        // Create updated concentrations for this formula
        const updatedFormulaConcentrations = {
          ...formulaConcentrations,
          [id]: {
            ...formulaConcentrations[id],
            concentration: numericValue,
          },
        };

        // Calculate total for validation
        const totalConcentration = Object.values(
          updatedFormulaConcentrations
        ).reduce((sum, current) => sum + (current.concentration || 0), 0);

        if (totalConcentration > 100) {
          setErrorMessage("Total concentration exceeds 100%");
        } else {
          setErrorMessage("");
        }

        // Return updated state with formula isolation
        return {
          ...prev,
          [activeFormulaId]: updatedFormulaConcentrations,
        };
      });
    }, 300),
    [activeFormulaId]
  );

  // Generate tree data from ingredients
  const { treeData, totalConcentration } = useMemo(() => {
    // Get the formula-specific concentrations
    const formulaConcentrations = concentrationsMap[activeFormulaId] || {};

    const { treeData, totalConcentration } = IngredientsUtils.getTreeData(
      selectedIngredients,
      formulaConcentrations,
      subIngredientsMap
    );

    const summary = [
      {
        id: "summary-row",
        concentration: totalConcentration,
        isSummary: true,
      },
    ];

    return {
      treeData: [...treeData, ...summary],
      totalConcentration,
    };
  }, [
    selectedIngredients,
    concentrationsMap,
    activeFormulaId,
    subIngredientsMap,
  ]);

  // Calculate end formula concentration for an ingredient
  const calculateEndFormulaConcentration = (row) => {
    const endFormulaConcentration =
      IngredientsUtils.calculateEndFormulaConcentration(row, treeData);

    // Only log for subingredients to see how end formula concentrations are calculated
    if (row.parentId && row.inci_name) {
      console.log(
        `End formula concentration for ${row.inci_name}: ${endFormulaConcentration}%`
      );
    }

    return endFormulaConcentration;
  };

  // Handle Excel export
  const handleExportToExcel = () => {
    const formulaConcentrations = concentrationsMap[activeFormulaId] || {};
    
    // Get the final INCI string from the InciNamesList component
    // Calculate it directly here using the same logic as in InciNamesList
    const finalInciString = (() => {
      if (!inciNames || !inciNames.flat || inciNames.flat.length === 0) return "N/A";
      
      // Sort according to standard INCI rules (descending concentration, alphabetical for <1%)
      const sortedList = [...inciNames.flat].sort((a, b) => {
        const concA = parseFloat(a.concentration) || 0;
        const concB = parseFloat(b.concentration) || 0;

        if (concA >= 1 && concB >= 1) {
          return concB - concA; // Sort descending > 1%
        } else if (concA < 1 && concB < 1) {
          // Sort alphabetically < 1% (case-insensitive)
          const nameA = (a.inciName || a.inci_name || "").toUpperCase();
          const nameB = (b.inciName || b.inci_name || "").toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        } else if (concA >= 1 && concB < 1) {
          return -1; // Ingredients >= 1% come first
        } else { // concA < 1 && concB >= 1
          return 1; // Ingredients >= 1% come first
        }
      });

      // Join names with ", "
      return sortedList.map(item => item.inciName || item.inci_name).join(", ");
    })();
    
    IngredientsUtils.exportToExcel(treeData, formulaConcentrations, finalInciString);
  };


  // Handler for when the InciNamesList component updates
  const handleInciNamesUpdate = (updatedInciNames) => {
    console.log("📝 InciNamesList reported changes:", updatedInciNames);
    // Directly update the state and mark the source as edited
    setInciNames(updatedInciNames);
    setInciSource("edited"); // Mark that the user manually edited the list
    setSnackbarMessage(
      "INCI name changes staged. Save the formula to persist."
    );
    setSnackbarOpen(true);
  };

  const handleRecalculateInci = () => {
    if (!activeFormulaId) {
      alert("No active formula.");
      return;
    }
    console.log("🔄 Recalculating INCI list...");
    const formulaConcentrations = concentrationsMap[activeFormulaId] || {};
    const calculatedInci = IngredientsUtils.calculateInciNames(
      selectedIngredients,
      formulaConcentrations,
      subIngredientsMap
    );
    setInciNames(calculatedInci);
    setInciSource("calculated"); // Mark as freshly calculated
    setSnackbarMessage("INCI list recalculated.");
    setSnackbarOpen(true);
  };

  const handleSaveOrUpdateFormula = async (
    folderId = null,
    formulaName = "",
    saveAsNew = false
  ) => {
    const isUpdating = isEditing && editingFormulaId && !saveAsNew;
    const targetFormulaId = isUpdating ? editingFormulaId : null; // Null for new/saveAsNew

    console.log(
      `🚀 ${
        isUpdating ? "Updating" : "Saving"
      } formula. Target ID: ${targetFormulaId}, SaveAsNew: ${saveAsNew}`
    );

    if (!activeFormulaId) {
      alert("Error: No active formula context.");
      return;
    }

    // Get current context data
    const currentFormulaConcentrations =
      concentrationsMap[activeFormulaId] || {};

    // Prepare ingredients and subingredients (deep clone)
    const finalIngredients = selectedIngredients.map((ing) => {
      const concentration =
        parseFloat(
          currentFormulaConcentrations[`ingredient-${ing.id}`]?.concentration
        ) || 0;
      const finalSubingredients = (subIngredientsMap[ing.id] || []).map(
        (sub) => {
          const subPercentActive = parseFloat(sub.percent_active) || 0;
          const concentrationInEndFormula =
            (concentration * subPercentActive) / 100;
          return {
            ...JSON.parse(JSON.stringify(sub)),
            concentrationInEndFormula: concentrationInEndFormula.toFixed(2),
          };
        }
      );
      return {
        ...JSON.parse(JSON.stringify(ing)),
        subingredients: finalSubingredients,
      };
    });

    // Prepare concentrations (only those for selected ingredients)
    const finalConcentrations = {};
    selectedIngredients.forEach((ing) => {
      const key = `ingredient-${ing.id}`;
      if (currentFormulaConcentrations[key]) {
        finalConcentrations[key] = {
          concentration: currentFormulaConcentrations[key].concentration,
        };
      } else {
        finalConcentrations[key] = { concentration: 0 }; // Ensure all ingredients have a concentration entry
      }
    });

    // *** Prepare INCI Names - Use the current state directly with validation ***
    let finalInciNames;
    try {
      // Validate and sanitize INCI names object before cloning
      if (!inciNames || typeof inciNames !== 'object') {
        console.warn("Invalid inciNames format, creating default structure");
        finalInciNames = { flat: [], hierarchical: [] };
      } else {
        // Ensure the object has the expected structure
        if (!Array.isArray(inciNames.flat)) {
          console.warn("inciNames.flat is not an array, creating default structure");
          finalInciNames = { 
            flat: Array.isArray(inciNames) ? [...inciNames] : [], 
            hierarchical: []
          };
        } else {
          // Deep clone with proper structure
          finalInciNames = {
            flat: JSON.parse(JSON.stringify(inciNames.flat || [])),
            hierarchical: JSON.parse(JSON.stringify(inciNames.hierarchical || []))
          };
        }
      }
    } catch (err) {
      console.error("Error processing INCI names:", err);
      finalInciNames = { flat: [], hierarchical: [] };
    }

    // Construct the payload
    const payload = {
      name: formulaName || "Untitled Formula", // Use provided name or default
      folderId: folderId || selectedFolder || null, // Use provided, selected, or null
      data: {
        ingredients: finalIngredients,
        concentrations: finalConcentrations,
        inciNames: finalInciNames, // Send the current INCI state
      },
    };

    console.log("💾 Payload being sent:", payload);

    try {
      let response;
      if (isUpdating) {
        // --- Use PUT for updates ---
        response = await api.put(`/api/formulas/${targetFormulaId}`, payload, {
          headers: {
            "x-access-token": AuthService.getCurrentUser().accessToken,
          },
        });
        console.log("✅ Formula updated successfully:", response.data);
        // Show success message in Snackbar instead of alert
        setSnackbarMessage("Formula updated successfully!");
        setSnackbarOpen(true);
        // Update local state based on response if needed, or refetch
        // Assuming the update was successful, the DB matches the state we sent
        setInciSource("loaded"); // Mark as 'loaded' since it now matches DB
        
        // Refresh the sidebar to show the updated formula
        if (refreshSidebar) {
          console.log("Refreshing sidebar after formula update");
          refreshSidebar();
        }
      } else {
        // --- Use POST for new or Save As New ---
        response = await api.post("/api/formulas", payload, {
          headers: {
            "x-access-token": AuthService.getCurrentUser().accessToken,
          },
        });
        const newFormulaId = response.data?.formulaId; // Get ID from backend response
        console.log(`✅ Formula saved successfully! New ID: ${newFormulaId}`);
        // Show success message in Snackbar instead of alert
        setSnackbarMessage("Formula saved successfully!");
        setSnackbarOpen(true);
        
        // Refresh the sidebar to show the new formula
        if (refreshSidebar) {
          console.log("Refreshing sidebar after new formula save");
          refreshSidebar();
        }
        
        if (newFormulaId) {
          // If saved successfully, load the newly created/saved formula
          setIsEditing(true);
          setEditingFormulaId(newFormulaId);
          navigate(`/formula/${newFormulaId}`, { replace: true }); // Navigate to the new formula URL
          handleLoadFormula(newFormulaId); // Load it
        } else {
          // Fallback if ID not returned? Reset state maybe?
          resetState();
        }
      }

      // Refresh sidebar lists
      fetchAllData(); // You might want finer control than fetching everything
    } catch (error) {
      console.error("❌ Error saving/updating formula:", error);
      
      // Enhanced error diagnostics
      let errorDetails = "Unknown error";
      
      if (error.response?.data) {
        console.log("Server response data:", error.response.data);
        if (typeof error.response.data === 'object') {
          errorDetails = JSON.stringify(error.response.data);
        } else if (error.response.data.message) {
          errorDetails = error.response.data.message;
        } else {
          errorDetails = String(error.response.data);
        }
      } else if (error.message) {
        errorDetails = error.message;
        console.log("Error message:", error.message);
        
        // This is likely where e.startsWith is not a function occurs
        if (error.message.includes('startsWith') || error.message.includes('TypeError')) {
          console.log("Stack trace:", error.stack);
          errorDetails = "Internal error processing INCI names. The formula was saved successfully.";
          
          // Show success message anyway since the formula was likely saved
          setSnackbarMessage("Formula saved successfully with some INCI processing issues");
          setSnackbarOpen(true);
          
          // Try to refresh sidebar anyway
          if (refreshSidebar) {
            refreshSidebar();
          }
          
          // Return early to prevent showing error message
          return;
        }
      }
      
      setSnackbarMessage(`Failed to save/update formula: ${errorDetails}`);
      setSnackbarOpen(true);
    } finally {
      setOpenModal(false); // Close folder modal
      setActionType(null);
    }
  };

  // Define tree data path for DataGrid with proper error handling
  const getTreeDataPath = (row) => {
    if (!row) {
      console.warn('getTreeDataPath received undefined row');
      return ['Unknown'];
    }
    
    try {
      return IngredientsUtils.getTreeDataPath(row, treeData);
    } catch (error) {
      console.error('Error in getTreeDataPath:', error, 'Row:', row);
      // Fallback to a safe but unique path based on row ID
      return [`Error-${row.id || 'unknown'}`];
    }
  };

  if (loading) {
    return <CircularProgress />;
  }


  return (
    <Box
      sx={{
        flexGrow: 1,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        width: "100%",
      }}
    >
      {/* Search component */}
      <IngredientSearch onAddIngredient={handleAddIngredient} />

      {/* Error message display */}
      {errorMessage && (
        <Typography
          color="error"
          variant="body2"
          sx={{ width: "100%", textAlign: "center" }}
        >
          {errorMessage}
        </Typography>
      )}

      {/* Data grid component */}
      <IngredientsGrid
        treeData={treeData}
        renderKey={renderKey}
        totalConcentration={totalConcentration}
        getTreeDataPath={getTreeDataPath}
        handleConcentrationBlur={handleConcentrationBlur}
        concentrations={getFormulaConcentrations()}
        moveRow={moveRow}
        selectedIngredients={selectedIngredients}
        calculateEndFormulaConcentration={calculateEndFormulaConcentration}
        onExportToExcel={handleExportToExcel}
        onGeneratePublicUrl={handleGeneratePublicUrl}
        onRemoveIngredient={handleRemoveIngredient} 
      />

      {/* INCI names list */}
      <InciNamesList
        inciNames={inciNames}
        onUpdate={handleInciNamesUpdate}
        source={inciSource} // Pass source for context in child
        onRecalculate={handleRecalculateInci}
      />

      {/* Action buttons */}
      <FormulaActionButtons
        isEditing={isEditing}
        onUpdateFormula={handleUpdateFormulaClick}
        onSaveAsNewFormula={handleSaveAsNewFormulaClick}
      />

      {/* Folder module for saving formula */}
      <FolderModule
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setActionType(null);
        }}
        folders={folders}
        onSave={(folderId, formulaName) =>
          handleSaveOrUpdateFormula(folderId, formulaName, actionType === 'saveAsNew')
        }
        refreshFolders={fetchAllData}
        actionType={actionType}
        initialFormulaName={formulaName}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        ContentProps={{
          sx: {
            bgcolor: snackbarMessage.includes('Failed') ? '#d32f2f' : '#43a047',
            color: 'white',
            fontWeight: 'medium',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2
          }
        }}
        message={snackbarMessage}
        action={
          publicUrl && snackbarMessage.includes('Public URL') ? (
            <Button
              color="inherit"
              size="small"
              onClick={() => navigator.clipboard.writeText(publicUrl)}
            >
              Copy
            </Button>
          ) : (
            <IconButton 
              size="small" 
              color="inherit" 
              onClick={handleSnackbarClose}
            >
              ✖
            </IconButton>
          )
        }
      />
    </Box>
  );
};

export default IngredientsList;
