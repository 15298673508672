import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';

const FormulaActionButtons = ({
  isEditing,
  onUpdateFormula,
  onSaveAsNewFormula
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 2,
      }}
    >
      <ButtonGroup
        sx={{ gap: '5px', display: 'flex', justifyContent: 'center' }}
      >
        {isEditing && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onUpdateFormula}
          >
            Update Formula
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={onSaveAsNewFormula}
        >
          Save Formula
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default FormulaActionButtons;