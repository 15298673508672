import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";
import { ThemeContext } from "../contexts/ThemeContext";
import { 
  Box, Typography, 
  Snackbar, Alert, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Menu, MenuItem, Checkbox, Divider,
  TableSortLabel, Tooltip, useTheme,
  Card, CardContent, Button, Stack
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import config from '../config';
import { SearchAutocomplete, ActionButton } from './common';

const api = axios.create({
  baseURL: config.API_URL,
});

// Define all available columns
const ALL_COLUMNS = [
  { field: "sap_code", label: "SAP Code", width: 120 },
  { field: "trade_name", label: "Trade Name", width: 200 },
  { field: "inci_name", label: "INCI Name", width: 200 },
  { field: "einecs_no", label: "EINECS NO", width: 150 },
  { field: "cas_no", label: "CAS No", width: 120 },
  { field: "percent_active", label: "% Active", width: 100, numeric: true },
  { field: "manufacturer", label: "Manufacturer", width: 150 },
  { field: "country_of_origin", label: "Country of Origin", width: 150 },
  { field: "function", label: "Functions", width: 200 },
  // Add more columns as needed
];

const IngredientSearchEdit = () => {
  const theme = useTheme();
  const { mode } = useContext(ThemeContext);
  
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [subingredients, setSubingredients] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // State for cell editing
  const [editingCell, setEditingCell] = useState(null);
  const [editValue, setEditValue] = useState('');
  
  // State for toggling subingredients visibility
  const [showSubingredients, setShowSubingredients] = useState(true);
  
  // State for sorting
  const [sortModel, setSortModel] = useState({
    field: 'trade_name',
    direction: 'asc'
  });
  
  // State for visible columns
  const [visibleColumns, setVisibleColumns] = useState(
    ALL_COLUMNS.map(col => col.field)
  );
  
  // Column selector menu state
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);

  // Fetch ingredients search results
  const fetchIngredients = async (query) => {
    try {
      const token = AuthService.getCurrentUser()?.accessToken; 
      if (!token) {
        throw new Error("User is not authenticated.");
      }
  
      const response = await api.get(`/api/ingredients/search?query=${query}`, {
        headers: { "x-access-token": token },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error searching ingredients:", error);
      setSnackbar({
        open: true,
        message: "Error searching ingredients",
        severity: 'error'
      });
      return [];
    }
  };
  
  // Fetch subingredients for a selected ingredient
  const fetchSubingredients = async (ingredientId) => {
    try {
      const token = AuthService.getCurrentUser()?.accessToken;
      if (!token) {
        throw new Error("User is not authenticated.");
      }
      
      const response = await api.get(`/api/ingredients/${ingredientId}/subingredients`, {
        headers: { "x-access-token": token },
      });
      
      return response.data;
    } catch (error) {
      console.error("Error fetching subingredients:", error);
      setSnackbar({
        open: true,
        message: "Error fetching subingredients",
        severity: 'error'
      });
      return [];
    }
  };

  // Handle selecting an ingredient from the search results
  const handleSelectIngredient = async (ingredient) => {
    if (!ingredient) {
      setSelectedIngredient(null);
      setSubingredients([]);
      return;
    }
    
    setSelectedIngredient({...ingredient});
    
    // Fetch subingredients when selecting an ingredient
    const subs = await fetchSubingredients(ingredient.id);
    setSubingredients([...subs]);
  };
  
  // Add a new empty subingredient
  const handleAddSubingredient = () => {
    if (!selectedIngredient) return;
    
    const newSubingredient = {
      id: `new-${Date.now()}`, // Temporary ID
      ingredient_id: selectedIngredient.id,
      inci_name: '',
      percent_active: '',
      cas_no: '',
      einecs_no: '',
      isNew: true
    };
    
    setSubingredients(prev => [...prev, newSubingredient]);
    // Make sure subingredients are visible when adding a new one
    setShowSubingredients(true);
  };

  // Handle starting to edit a cell
  const handleStartEdit = (rowType, rowId, field, currentValue) => {
    setEditingCell({ rowType, rowId, field });
    setEditValue(currentValue || '');
  };

  // Handle saving a cell edit
  const handleSaveEdit = () => {
    if (!editingCell) return;
    
    const { rowType, rowId, field } = editingCell;
    
    if (rowType === 'ingredient') {
      // Update the main ingredient
      setSelectedIngredient(prev => ({
        ...prev,
        [field]: editValue
      }));
      console.log(`Updated main ingredient field ${field} to "${editValue}"`);
    } else if (rowType === 'subingredient') {
      // Update a subingredient
      setSubingredients(prev => 
        prev.map(sub => 
          sub.id.toString() === rowId.toString() 
            ? { ...sub, [field]: editValue } 
            : sub
        )
      );
      console.log(`Updated subingredient ${rowId} field ${field} to "${editValue}"`);
    }
    
    // Clear the editing state
    setEditingCell(null);
  };

  // Handle keydown events in the editing TextField
  const handleEditKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveEdit();
    } else if (event.key === 'Escape') {
      setEditingCell(null); // Cancel editing
    }
  };

  // Update the ingredient and its subingredients on the server
  const handleUpdate = async () => {
    if (!selectedIngredient) return;
  
    setUpdateLoading(true);
    try {
      const token = AuthService.getCurrentUser()?.accessToken; 
      if (!token) {
        throw new Error("User is not authenticated.");
      }
      
      console.log('Updating ingredient with data:', selectedIngredient);
      
      // First update the main ingredient
      const ingredientResponse = await api.put(
        `/api/ingredients/${selectedIngredient.id}`, 
        selectedIngredient, 
        { headers: { "x-access-token": token } }
      );
      
      console.log('Ingredient update response:', ingredientResponse.data);
      
      // If the response includes updated data, use it to update the UI
      if (ingredientResponse.data && ingredientResponse.data.data) {
        setSelectedIngredient(ingredientResponse.data.data);
      }

      // Update each subingredient
      for (const sub of subingredients) {
        const isNew = sub.id.toString().startsWith('new-');
        const subData = { ...sub };
        
        // Remove UI-specific properties
        delete subData.isNew;
        
        if (isNew) {
          // Remove the temporary ID for new subingredients
          delete subData.id;
          
          // This is a new subingredient, create it
          console.log('Creating new subingredient with data:', subData);
          const createResponse = await api.post(
            `/api/ingredients/${selectedIngredient.id}/subingredients`, 
            subData, 
            { headers: { "x-access-token": token } }
          );
          console.log('Create subingredient response:', createResponse.data);
        } else {
          // This is an existing subingredient, update it
          console.log(`Updating subingredient ${sub.id} with data:`, subData);
          const updateResponse = await api.put(
            `/api/ingredients/subingredients/${sub.id}`, 
            subData, 
            { headers: { "x-access-token": token } }
          );
          console.log('Update subingredient response:', updateResponse.data);
        }
      }
      
      // Refresh the subingredients after updates
      const updatedSubs = await fetchSubingredients(selectedIngredient.id);
      setSubingredients(updatedSubs);
      
      setSnackbar({
        open: true,
        message: "Ingredient and subingredients updated successfully!",
        severity: 'success'
      });
    } catch (error) {
      console.error("Error updating ingredient:", error);
      setSnackbar({
        open: true,
        message: "Failed to update ingredient: " + (error.response?.data?.message || error.message),
        severity: 'error'
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  // Toggle showing/hiding subingredients
  const toggleSubingredients = () => {
    setShowSubingredients(prev => !prev);
  };

  // Handle sorting
  const handleSort = (field) => {
    setSortModel(prev => ({
      field,
      direction: 
        prev.field === field && prev.direction === 'asc' 
          ? 'desc' 
          : 'asc'
    }));
  };

  // Get sorted data
  const sortedSubingredients = useMemo(() => {
    if (!subingredients.length) return [];
    
    const { field, direction } = sortModel;
    
    return [...subingredients].sort((a, b) => {
      const aValue = a[field] || '';
      const bValue = b[field] || '';
      
      // Handle numeric sorting
      const column = ALL_COLUMNS.find(col => col.field === field);
      if (column && column.numeric) {
        const aNum = parseFloat(aValue) || 0;
        const bNum = parseFloat(bValue) || 0;
        return direction === 'asc' ? aNum - bNum : bNum - aNum;
      }
      
      // Handle string sorting
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      // Handle other cases
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [subingredients, sortModel]);

  // Toggle column visibility
  const toggleColumnVisibility = (field) => {
    setVisibleColumns(prev => {
      if (prev.includes(field)) {
        return prev.filter(f => f !== field);
      } else {
        return [...prev, field];
      }
    });
  };

  // Filter visible columns
  const visibleColumnDefs = useMemo(() => {
    return ALL_COLUMNS.filter(col => visibleColumns.includes(col.field))
      .sort((a, b) => {
        // Sort columns in the same order as visibleColumns array
        return visibleColumns.indexOf(a.field) - visibleColumns.indexOf(b.field);
      });
  }, [visibleColumns]);

  // Handle column menu open/close
  const handleColumnMenuOpen = (event) => {
    setColumnMenuAnchor(event.currentTarget);
  };

  const handleColumnMenuClose = () => {
    setColumnMenuAnchor(null);
  };

  // Render a cell with edit capability
  const renderEditableCell = (rowType, rowId, field, value) => {
    const isEditing = 
      editingCell && 
      editingCell.rowType === rowType && 
      editingCell.rowId === rowId && 
      editingCell.field === field;
    
    if (isEditing) {
      return (
        <input
          autoFocus
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onBlur={handleSaveEdit}
          onKeyDown={handleEditKeyDown}
          style={{
            width: '100%',
            padding: '8px 12px',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '8px',
            fontSize: '14px',
            backgroundColor: mode === 'dark' ? theme.palette.background.paper : '#ffffff',
            color: theme.palette.text.primary,
            outline: 'none'
          }}
        />
      );
    }
    
    return (
      <div 
        onClick={() => handleStartEdit(rowType, rowId, field, value)}
        style={{ 
          cursor: 'pointer', 
          minHeight: '24px', 
          width: '100%',
          padding: '4px 0',
          borderRadius: '4px',
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        {value || ''}
      </div>
    );
  };

  // Handle snackbar close
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ 
      padding: 3,
      maxWidth: 1400,
      mx: 'auto'
    }}>
      <Card 
        elevation={1} 
        sx={{ 
          p: 3, 
          mb: 4, 
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Typography 
            variant="h4" 
            fontWeight="600" 
            gutterBottom
            sx={{ mb: 3 }}
          >
            Search and Edit Ingredients
          </Typography>

          <Typography 
            variant="body1"
            color="text.secondary"
            sx={{ mb: 3 }}
          >
            Search for ingredients and edit their properties, including sub-ingredients.
          </Typography>

          {/* Search Dropdown */}
          <Box sx={{ mb: 3 }}>
            <SearchAutocomplete
              fetchSearchResults={fetchIngredients}
              onSelect={(event, newValue) => handleSelectIngredient(newValue)}
              getOptionLabel={(option) => option.trade_name || ''}
              label="Search Ingredients"
              initialOptions={ingredients}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
            />
          </Box>
        </CardContent>
      </Card>

      {/* Ingredient and Subingredients Table */}
      {selectedIngredient && (
        <Card 
          elevation={1} 
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <CardContent sx={{ p: 0 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              p: 3,
              borderBottom: `1px solid ${theme.palette.divider}`
            }}>
              <Typography variant="h5" fontWeight="600">
                {selectedIngredient.trade_name}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleAddSubingredient}
                  startIcon={<AddIcon />}
                  sx={{ borderRadius: 2 }}
                >
                  Add Subingredient
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleUpdate}
                  disabled={updateLoading}
                  startIcon={<SaveIcon />}
                  sx={{ borderRadius: 2 }}
                >
                  {updateLoading ? "Saving..." : "Save Changes"}
                </Button>
              </Stack>
            </Box>
            
            {/* Table Toolbar */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              p: 2,
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
              borderBottom: `1px solid ${theme.palette.divider}`
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={showSubingredients ? "Hide subingredients" : "Show subingredients"}>
                  <IconButton 
                    onClick={toggleSubingredients}
                    disabled={subingredients.length === 0}
                    size="small"
                    color="primary"
                  >
                    {showSubingredients ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                  </IconButton>
                </Tooltip>
                <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                  {subingredients.length} subingredient{subingredients.length !== 1 ? 's' : ''}
                </Typography>
              </Box>
              
              <Tooltip title="Column Selection">
                <IconButton 
                  onClick={handleColumnMenuOpen} 
                  size="small"
                  color="primary"
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              
              {/* Column Selection Menu */}
              <Menu
                anchorEl={columnMenuAnchor}
                open={Boolean(columnMenuAnchor)}
                onClose={handleColumnMenuClose}
                PaperProps={{
                  elevation: 1,
                  sx: { 
                    borderRadius: 2,
                    boxShadow: mode === 'dark' 
                      ? '0 4px 20px rgba(0, 0, 0, 0.5)' 
                      : '0 4px 20px rgba(0, 0, 0, 0.1)'
                  }
                }}
              >
                <Typography sx={{ p: 2, pb: 1 }} variant="subtitle2" fontWeight="600">
                  Select Visible Columns
                </Typography>
                <Divider />
                {ALL_COLUMNS.map(column => (
                  <MenuItem 
                    key={column.field}
                    dense
                    onClick={() => toggleColumnVisibility(column.field)}
                  >
                    <Checkbox 
                      checked={visibleColumns.includes(column.field)} 
                      size="small"
                      color="primary"
                    />
                    {column.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            
            {/* Custom Table */}
            <TableContainer 
              sx={{ 
                maxWidth: '100%',
                overflow: 'auto'
              }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell 
                      width={50}
                      sx={{ 
                        backgroundColor: mode === 'dark' ? 'background.card' : 'background.paper',
                        fontWeight: 600
                      }}
                    >
                      #
                    </TableCell>
                    {visibleColumnDefs.map((column) => (
                      <TableCell 
                        key={column.field} 
                        width={column.width}
                        sortDirection={sortModel.field === column.field ? sortModel.direction : false}
                        sx={{ 
                          backgroundColor: mode === 'dark' ? 'background.card' : 'background.paper',
                          fontWeight: 600
                        }}
                      >
                        <TableSortLabel
                          active={sortModel.field === column.field}
                          direction={sortModel.field === column.field ? sortModel.direction : 'asc'}
                          onClick={() => handleSort(column.field)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Main Ingredient Row */}
                  <TableRow 
                    sx={{ 
                      backgroundColor: mode === 'dark' 
                        ? 'rgba(0, 122, 255, 0.1)' 
                        : 'rgba(0, 122, 255, 0.05)',
                      '&:hover': { 
                        backgroundColor: mode === 'dark' 
                          ? 'rgba(0, 122, 255, 0.15)' 
                          : 'rgba(0, 122, 255, 0.1)' 
                      } 
                    }}
                  >
                    <TableCell>
                      <IconButton 
                        size="small" 
                        onClick={toggleSubingredients}
                        disabled={subingredients.length === 0}
                        color="primary"
                      >
                        {showSubingredients ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                      </IconButton>
                    </TableCell>
                    {visibleColumnDefs.map((column) => (
                      <TableCell 
                        key={column.field}
                        sx={{ 
                          borderColor: theme.palette.divider,
                          fontWeight: 500
                        }}
                      >
                        {renderEditableCell(
                          'ingredient', 
                          selectedIngredient.id, 
                          column.field, 
                          selectedIngredient[column.field]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  
                  {/* Subingredient Rows */}
                  {showSubingredients && sortedSubingredients.map((sub, index) => (
                    <TableRow 
                      key={sub.id} 
                      sx={{ 
                        backgroundColor: mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.03)' 
                          : 'rgba(0, 0, 0, 0.02)',
                        '&:hover': { 
                          backgroundColor: mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.07)' 
                            : 'rgba(0, 0, 0, 0.05)' 
                        },
                        borderBottom: `1px solid ${theme.palette.divider}`
                      }}
                    >
                      <TableCell>
                        <Typography variant="body2" color="text.secondary" sx={{ pl: 2 }}>
                          {index + 1}
                        </Typography>
                      </TableCell>
                      {visibleColumnDefs.map((column) => (
                        <TableCell 
                          key={`${sub.id}-${column.field}`}
                          sx={{ borderColor: theme.palette.divider }}
                        >
                          {renderEditableCell(
                            'subingredient',
                            sub.id,
                            column.field,
                            sub[column.field]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            {/* Empty State for Subingredients */}
            {showSubingredients && sortedSubingredients.length === 0 && (
              <Box 
                sx={{ 
                  p: 4, 
                  textAlign: 'center',
                  borderTop: `1px solid ${theme.palette.divider}`
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: 40, color: 'text.secondary', mb: 2, opacity: 0.7 }} />
                <Typography variant="body1" color="text.secondary" gutterBottom>
                  No subingredients found
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={handleAddSubingredient}
                  startIcon={<AddIcon />}
                  sx={{ mt: 2, borderRadius: 2 }}
                >
                  Add First Subingredient
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      )}
      
      {/* Empty state when no ingredient is selected */}
      {!selectedIngredient && (
        <Card 
          elevation={0} 
          sx={{ 
            p: 6, 
            textAlign: 'center',
            backgroundColor: mode === 'dark' ? 'background.paper' : 'rgba(0, 0, 0, 0.02)',
            border: `1px dashed ${theme.palette.divider}`,
            borderRadius: 2
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2, opacity: 0.5 }} />
          <Typography variant="h5" color="text.secondary" gutterBottom fontWeight="600">
            No Ingredient Selected
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Search for an ingredient above to view and edit its details
          </Typography>
        </Card>
      )}
      
      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          elevation={6}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IngredientSearchEdit;