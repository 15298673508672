import React from 'react';
import axios from 'axios';
import AuthService from '../../services/auth.service';
import config from '../../config';
import { SearchAutocomplete } from '../common';

const api = axios.create({
  baseURL: config.API_URL,
});

const IngredientSearch = ({ onAddIngredient }) => {
  const fetchIngredients = async (query) => {
    console.log(`[IngredientSearch] fetchIngredients called with query: "${query}"`);
    try {
      const response = await api.get(`/api/ingredients/search?query=${query}`, {
        headers: {
          "x-access-token": AuthService.getCurrentUser()?.accessToken,
        },
      });
      
      // Just return the data directly without additional processing
      // This matches the working implementation in IngredientSearchEdit
      console.log(`[IngredientSearch] API returned ${response.data?.length || 0} results`);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching search results:", error);
      return [];
    }
  };

  // Adding key prop to ensure proper re-mounting when there are issues
  return (
    <SearchAutocomplete 
      key="ingredientsSearch"
      fetchSearchResults={fetchIngredients} 
      onSelect={onAddIngredient}
      getOptionLabel={(option) => option.trade_name || ""}
      label="Search Ingredients"
    />
  );
};

export default IngredientSearch;