import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Divider,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import AuthService from "../services/auth.service";
import { ThemeContext } from "../contexts/ThemeContext";
import config from "../config";

const api = axios.create({
  baseURL: config.API_URL,
});

const AddIngredient = ({ refreshIngredients }) => {
  const theme = useTheme();
  const { mode } = useContext(ThemeContext);
  
  const [ingredientData, setIngredientData] = useState({
    cas_no: "",
    country_of_origin: "",
    einecs_no: "",
    function: "",
    inci_name: "",
    manufacturer: "",
    percent_active: "",
    reach: "",
    sap_code: "",
    trade_name: "",
    restrictions: "",
    status: "",
    price: "",
    vegan: "",
    iso_16128_natural_origin_ingredient: "",
    supplier: "",
    no_animal_testing_declaration: "",
    sds_validity_3_years: "",
    organic: "",
    cosmos_certification: "",
    gluten_free: "",
    gmo: "",
    microplastic: "",
    rspo: "",
    rspo_no: "",
    fairtrade: "",
    origin_of_raw_material: "",
    microbiological_analysis: "",
    solubility: "",
  });

  const [hasSubIngredients, setHasSubIngredients] = useState(false);
  const [subIngredients, setSubIngredients] = useState([]);
  const [saving, setSaving] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Handle input changes for the main ingredient
  const handleIngredientChange = (e) => {
    const { name, value } = e.target;
    setIngredientData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle adding a new sub-ingredient
  const addSubIngredient = () => {
    setSubIngredients([
      ...subIngredients,
      {
        cas_no: "",
        einecs_no: "",
        inci_name: "",
        percent_active: "",
        restrictions: "",
        origin_of_raw_material: "",
        function: "",
      },
    ]);
  };

  // Handle input changes for sub-ingredients
  const handleSubIngredientChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSubIngredients = [...subIngredients];
    updatedSubIngredients[index][name] = value;
    setSubIngredients(updatedSubIngredients);
  };

  // Handle the form submission
  const handleSubmit = async () => {
    try {
      setSaving(true);
      const token = AuthService.getCurrentUser().accessToken;
  
      // Ensure numeric fields are valid numbers or set to null
      const cleanedData = {
        ...ingredientData,
        percent_active: ingredientData.percent_active ? parseFloat(ingredientData.percent_active) : null,
        price: ingredientData.price ? parseFloat(ingredientData.price) : null,
        iso_16128_natural_origin_ingredient: ingredientData.iso_16128_natural_origin_ingredient
          ? parseFloat(ingredientData.iso_16128_natural_origin_ingredient)
          : null,
        sds_validity_3_years: ingredientData.sds_validity_3_years && ingredientData.sds_validity_3_years !== 'Invalid date'
          ? ingredientData.sds_validity_3_years
          : null,
      };
  
      // Save the main ingredient
      const ingredientResponse = await api.post(
        "/api/ingredients",
        cleanedData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
  
      const ingredientId = ingredientResponse.data.id;
  
      // Save sub-ingredients if applicable
      if (hasSubIngredients && subIngredients.length > 0) {
        const subIngredientsData = subIngredients.map((sub) => ({
          ...sub,
          ingredient_id: ingredientId,
          percent_active: sub.percent_active ? parseFloat(sub.percent_active) : null,
        }));
  
        for (const subIngredient of subIngredientsData) {
          await api.post(
            "/api/ingredients/subingredients",
            subIngredient,
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
        }
      }
      
      if (refreshIngredients) {
        refreshIngredients();
      }
      setSnackbar({
        open: true,
        message: "Ingredient saved successfully!",
        severity: "success",
      });
  
      // Reset the form
      setIngredientData({
        cas_no: "",
        country_of_origin: "",
        einecs_no: "",
        function: "",
        inci_name: "",
        manufacturer: "",
        percent_active: "",
        reach: "",
        sap_code: "",
        trade_name: "",
        restrictions: "",
        status: "",
        price: "",
        vegan: "",
        iso_16128_natural_origin_ingredient: "",
        supplier: "",
        no_animal_testing_declaration: "",
        sds_validity_3_years: "",
        organic: "",
        cosmos_certification: "",
        gluten_free: "",
        gmo: "",
        microplastic: "",
        rspo: "",
        rspo_no: "",
        fairtrade: "",
        origin_of_raw_material: "",
        microbiological_analysis: "",
        solubility: "",
      });
      setSubIngredients([]);
      setHasSubIngredients(false);
    } catch (error) {
      console.error("Error adding ingredient:", error);
      setSnackbar({
        open: true,
        message: "Failed to add ingredient. Please try again.",
        severity: "error",
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Group fields into sections for better organization
  const fieldGroups = [
    {
      title: "Basic Information",
      fields: [
        { name: "sap_code", label: "SAP Code" },
        { name: "trade_name", label: "Trade Name" },
        { name: "inci_name", label: "INCI Name", disabled: hasSubIngredients },
        { name: "cas_no", label: "CAS No" },
        { name: "einecs_no", label: "EINECS No" },
        { name: "country_of_origin", label: "Country of Origin" },
        { name: "function", label: "Function" }
      ]
    },
    {
      title: "Manufacturer & Supply",
      fields: [
        { name: "manufacturer", label: "Manufacturer" },
        { name: "supplier", label: "Supplier" },
        { name: "percent_active", label: "Percent Active", type: "number" },
        { name: "price", label: "Price", type: "number" }
      ]
    },
    {
      title: "Regulatory Information",
      fields: [
        { name: "reach", label: "REACH" },
        { name: "restrictions", label: "Restrictions" },
        { name: "status", label: "Status" }
      ]
    },
    {
      title: "Certifications & Properties",
      fields: [
        { name: "vegan", label: "Vegan" },
        { name: "organic", label: "Organic" },
        { name: "cosmos_certification", label: "COSMOS Certification" },
        { name: "gluten_free", label: "Gluten Free" },
        { name: "gmo", label: "GMO" },
        { name: "microplastic", label: "Microplastic" },
        { name: "rspo", label: "RSPO" },
        { name: "rspo_no", label: "RSPO Number" },
        { name: "fairtrade", label: "Fairtrade" },
        { name: "no_animal_testing_declaration", label: "No Animal Testing" },
        { name: "iso_16128_natural_origin_ingredient", label: "ISO 16128 Natural Origin", type: "number" }
      ]
    },
    {
      title: "Additional Properties",
      fields: [
        { name: "origin_of_raw_material", label: "Origin of Raw Material" },
        { name: "microbiological_analysis", label: "Microbiological Analysis" },
        { name: "solubility", label: "Solubility" },
        { name: "sds_validity_3_years", label: "SDS Validity", type: "date" }
      ]
    }
  ];

  return (
    <Paper
      elevation={1}
      sx={{
        maxWidth: 1200,
        mx: 'auto',
        p: 3,
        borderRadius: 2,
        overflow: 'hidden'
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" fontWeight="600" gutterBottom>
          Add New Ingredient
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Fill in the details below to add a new ingredient to the database
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              mb: 4,
              borderRadius: 2,
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: mode === 'dark' ? 'background.paper' : 'rgba(0, 122, 255, 0.05)'
            }}
          >
            <Typography variant="subtitle1" fontWeight="600" gutterBottom>
              Sub-Ingredient Options
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasSubIngredients}
                  onChange={() => setHasSubIngredients(!hasSubIngredients)}
                  color="primary"
                />
              }
              label="This ingredient has sub-ingredients"
            />
            
            {hasSubIngredients && (
              <Box sx={{ mt: 2 }}>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  onClick={addSubIngredient}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ mb: 2 }}
                >
                  Add Sub-Ingredient
                </Button>
                
                {subIngredients.map((sub, index) => (
                  <Paper
                    key={index}
                    elevation={0}
                    sx={{
                      mt: 2,
                      p: 2,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 2,
                      backgroundColor: 'background.paper'
                    }}
                  >
                    <Typography variant="subtitle2" fontWeight="600" gutterBottom>
                      Sub-Ingredient {index + 1}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="INCI Name"
                          name="inci_name"
                          value={sub.inci_name}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Function"
                          name="function"
                          value={sub.function}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="CAS No"
                          name="cas_no"
                          value={sub.cas_no}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="EINECS No"
                          name="einecs_no"
                          value={sub.einecs_no}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Percent Active"
                          name="percent_active"
                          type="number"
                          value={sub.percent_active}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Restrictions"
                          name="restrictions"
                          value={sub.restrictions}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Origin of Raw Material"
                          name="origin_of_raw_material"
                          value={sub.origin_of_raw_material}
                          onChange={(e) => handleSubIngredientChange(index, e)}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="600" gutterBottom>
            Main Ingredient Details
          </Typography>
          
          {fieldGroups.map((group, groupIndex) => (
            <Accordion 
              key={groupIndex} 
              defaultExpanded={groupIndex === 0}
              sx={{
                mb: 2,
                '&:before': { display: 'none' },
                boxShadow: 'none',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '12px !important',
                overflow: 'hidden',
                '& .MuiAccordionSummary-root': {
                  borderRadius: 2
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.05)' 
                    : 'rgba(0, 0, 0, 0.02)',
                  '&:hover': {
                    backgroundColor: mode === 'dark' 
                      ? 'rgba(255, 255, 255, 0.08)' 
                      : 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <Typography variant="subtitle1" fontWeight="600">
                  {group.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {group.fields.map((field, fieldIndex) => (
                    <Grid item xs={12} sm={6} md={4} key={fieldIndex}>
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        type={field.type || "text"}
                        value={ingredientData[field.name]}
                        onChange={handleIngredientChange}
                        margin="normal"
                        variant="outlined"
                        disabled={field.disabled}
                        InputLabelProps={field.type === 'date' ? { shrink: true } : undefined}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 1.5,
                          }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={saving}
          startIcon={<SaveIcon />}
          size="large"
          sx={{ 
            borderRadius: 2,
            px: 4,
            py: 1.5
          }}
        >
          {saving ? "Saving..." : "Save Ingredient"}
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          elevation={6}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AddIngredient;