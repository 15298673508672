// Home.jsx

import { Typography, Button, Box, Paper, Container, Grid, useTheme } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import { ThemeContext } from '../contexts/ThemeContext';

const Home = ({resetEditingState}) => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const theme = useTheme();

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser && currentUser.username) {
      setUsername(currentUser.username);
    }
  }, []);

  const handleCreateNewFormula = () => {
    if (resetEditingState) {
      resetEditingState();
    } else {
    }
    navigate('/ingredients', {
      state: {
        isNewFormula: true,
        timestamp: Date.now() 
      },
      replace: false 
    });
  };

  return (
    <Container maxWidth="1600px">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        py={6}
      >
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4
          }}
        >
          <img 
            src={mode === 'dark' ? "/Inci_logo_only-white.webp" : "/Inci_logo_only-black.webp"} 
            alt="INCI Navigator Logo" 
            style={{ 
              width: "100px", 
              height: "auto", 
              marginBottom: "24px" 
            }} 
          />
          <Typography 
            variant="h3" 
            fontWeight="600" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
              marginBottom: 1
            }}
          >
            Welcome, {username}
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Paper 
              elevation={1} 
              sx={{ 
                p: 3, 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)'
                },
                cursor: 'pointer'
              }}
              onClick={handleCreateNewFormula}
            >
              <AddIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
              <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                Create New Formula
              </Typography>
              <Typography variant="body1" color="text.secondary" textAlign="center">
                Start a new formula from scratch
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} sm={6} md={6}>
            <Paper 
              elevation={1} 
              component={Link} 
              to="/addingredient"
              sx={{ 
                p: 3, 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)'
                },
                textDecoration: 'none',
                cursor: 'pointer'
              }}
            >
              <AddIcon sx={{ fontSize: 48, color: 'secondary.main', mb: 2 }} />
              <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                Add New Ingredients
              </Typography>
              <Typography variant="body1" color="text.secondary" textAlign="center">
                Add ingredients to your database
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} sm={6} md={6}>
            <Paper 
              elevation={1}
              component={Link}
              to="/editingredients"
              sx={{ 
                p: 3, 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)'
                },
                textDecoration: 'none',
                cursor: 'pointer'
              }}
            >
              <EditIcon sx={{ fontSize: 48, color: 'info.main', mb: 2 }} />
              <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                Edit Ingredients
              </Typography>
              <Typography variant="body1" color="text.secondary" textAlign="center">
                Modify existing ingredients
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} sm={6} md={6}>
            <Paper 
              elevation={1}
              component={Link}
              to="/uploadingredients"
              sx={{ 
                p: 3, 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)'
                },
                textDecoration: 'none',
                cursor: 'pointer'
              }}
            >
              <UploadIcon sx={{ fontSize: 48, color: 'warning.main', mb: 2 }} />
              <Typography variant="h5" fontWeight="600" textAlign="center" gutterBottom>
                Upload from Excel
              </Typography>
              <Typography variant="body1" color="text.secondary" textAlign="center">
                Import ingredients from a spreadsheet
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Home;