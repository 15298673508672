// src/ui/theme.js
import { createTheme } from '@mui/material/styles';

// Define our color palette
const colors = {
  light: {
    primary: {
      main: '#007aff', // Apple blue
      dark: '#0071e3',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#34c759', // Apple green 
      dark: '#30b350',
      contrastText: '#fff',
    },
    error: {
      main: '#ff3b30', // Apple red
      dark: '#e6352c',
    },
    warning: {
      main: '#ff9500', // Apple orange
      dark: '#e68600',
    },
    info: {
      main: '#5ac8fa', // Apple light blue
      dark: '#45b5e6',
    },
    success: {
      main: '#30d158', // Apple green
      dark: '#2bb94e',
    },
    customColor: {
      main: '#ff2d55', // Apple pink
      dark: '#e6284d',
      contrastText: '#fff',
    },
    background: {
      default: '#f2f2f7', // Apple light gray background
      paper: '#ffffff',
      card: '#ffffff',
      appBar: 'rgba(255, 255, 255, 0.85)',
    },
    text: {
      primary: '#000000', // Apple uses black text for primary
      secondary: '#8e8e93', // Apple gray for secondary text
    },
    divider: 'rgba(0, 0, 0, 0.05)',
    action: {
      hover: 'rgba(0, 122, 255, 0.08)',
    }
  },
  dark: {
    primary: {
      main: '#0A84FF', // Apple blue (dark mode)
      dark: '#0774e0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#30D158', // Apple green (dark mode)
      dark: '#28bd4e',
      contrastText: '#000000',
    },
    error: {
      main: '#FF453A', // Apple red (dark mode)
      dark: '#e63d33',
    },
    warning: {
      main: '#FF9F0A', // Apple orange (dark mode)
      dark: '#e68f09',
    },
    info: {
      main: '#64D2FF', // Apple light blue (dark mode)
      dark: '#5abde6',
    },
    success: {
      main: '#30D158', // Apple green (dark mode)
      dark: '#2bb94e',
    },
    customColor: {
      main: '#FF375F', // Apple pink (dark mode)
      dark: '#e63155',
      contrastText: '#fff',
    },
    background: {
      default: '#000000', // Apple dark background
      paper: '#1c1c1e', // Apple dark gray (level 1)
      card: '#2c2c2e', // Apple dark gray (level 2)
      appBar: 'rgba(28, 28, 30, 0.85)', // Translucent dark 
    },
    text: {
      primary: '#ffffff', // Apple uses white text for primary in dark mode
      secondary: '#8e8e93', // Apple gray for secondary text (same as light mode)
    },
    divider: 'rgba(255, 255, 255, 0.1)',
    action: {
      hover: 'rgba(10, 132, 255, 0.15)',
    }
  }
};

// Create a theme instance with light and dark mode
const getThemeOptions = (mode) => {
  const colorScheme = mode === 'dark' ? colors.dark : colors.light;
  
  return {
    palette: {
      mode,
      primary: colorScheme.primary,
      secondary: colorScheme.secondary,
      error: colorScheme.error,
      warning: colorScheme.warning,
      info: colorScheme.info,
      success: colorScheme.success,
      customColor: colorScheme.customColor,
      background: colorScheme.background,
      text: colorScheme.text,
      divider: colorScheme.divider,
      action: colorScheme.action,
    },
    typography: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      button: {
        textTransform: 'none', // Apple doesn't use uppercase buttons
        fontWeight: 500,
      },
      h4: {
        fontWeight: 600, // Apple uses semibold for headings
      },
      h5: {
        fontWeight: 600,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            backgroundColor: theme.palette.background.default,
            transition: 'background-color 0.2s ease',
          },
        }),
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8, // Apple uses rounded corners
            padding: '10px 20px',
            boxShadow: 'none', // Apple usually avoids shadows on buttons
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: colorScheme.primary.dark,
            },
          },
          containedSecondary: {
            '&:hover': {
              backgroundColor: colorScheme.secondary.dark,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              borderRadius: 8,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            boxShadow: mode === 'dark' 
              ? '0px 4px 20px rgba(0, 0, 0, 0.45)' 
              : '0px 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: 10,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 12, // Apple uses more rounded corners for cards
            backgroundImage: 'none', // Remove default gradient for dark mode
          },
          elevation1: {
            boxShadow: mode === 'dark' 
              ? '0px 2px 12px rgba(0, 0, 0, 0.2)' 
              : '0px 2px 12px rgba(0, 0, 0, 0.08)', // Subtle shadow like Apple
          },
          elevation3: {
            boxShadow: mode === 'dark' 
              ? '0px 4px 20px rgba(0, 0, 0, 0.3)' 
              : '0px 4px 20px rgba(0, 0, 0, 0.1)', // Medium shadow
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          },
          columnHeader: {
            backgroundColor: mode === 'dark' ? '#2c2c2e' : '#f2f2f7',
            fontWeight: 600,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRight: `1px solid ${colorScheme.divider}`,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: colorScheme.divider,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backdropFilter: 'blur(10px)',
            backgroundColor: colorScheme.background.appBar,
            borderBottom: `1px solid ${colorScheme.divider}`,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: colorScheme.action.hover, 
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: 8,
          },
          switchBase: {
            padding: 1,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: mode === 'dark' ? '#30D158' : '#0A84FF',
                opacity: 1,
                border: 0,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: colorScheme.primary.main,
              border: '6px solid #fff',
            },
          },
          thumb: {
            width: 24,
            height: 24,
          },
          track: {
            borderRadius: 26 / 2,
            border: `1px solid ${mode === 'dark' ? '#39393D' : '#E9E9EA'}`,
            backgroundColor: mode === 'dark' ? '#39393D' : '#E9E9EA',
            opacity: 1,
          },
        },
      },
    },
  };
};

// Create and export the light theme as default
const theme = createTheme(getThemeOptions('light'));

// Export theme generators for light and dark modes
export const lightTheme = createTheme(getThemeOptions('light'));
export const darkTheme = createTheme(getThemeOptions('dark'));

export default theme;