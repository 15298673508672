import * as XLSX from 'xlsx';

// Transform data for tree structure in the data grid
export const getTreeData = (selectedIngredients, concentrations, subIngredientsMap) => {
  const treeData = [];
  let totalConcentration = 0;

  selectedIngredients.forEach((ingredient, index) => {
    const ingredientId = `ingredient-${ingredient.id}`;
    const concentration =
      parseFloat(concentrations[ingredientId]?.concentration) || 0;
    totalConcentration += concentration;

    treeData.push({
      ...ingredient,
      id: ingredientId,
      parentId: null,
      order: index + 1,
      concentration: concentration,
      concentrationInEndFormula: concentration.toFixed(2),
      subingredients: subIngredientsMap[ingredient.id] || [],
    });

    const subs = subIngredientsMap[ingredient.id] || [];
    subs.forEach((sub) => {
      const subPercentActive = parseFloat(sub.percent_active) || 0;
      const concentrationInEndFormula =
        (concentration * subPercentActive) / 100;

      treeData.push({
        ...sub,
        id: `subingredient-${sub.id}`,
        parentId: ingredientId,
        order: "",
        concentration: subPercentActive,
        concentrationInEndFormula: concentrationInEndFormula.toFixed(2),
      });
    });
  });

  return { 
    treeData, 
    totalConcentration: Math.min(totalConcentration, 100) 
  };
};

// Calculate the end formula concentration for an ingredient
export const calculateEndFormulaConcentration = (row, treeData) => {
  if (!row) {
    return "0.00";
  }
  if (!row.parentId) {
    return row.concentrationInEndFormula || "0.00";
  }

  const parentId = row.parentId;
  const parentRow = treeData.find((item) => item.id === parentId);
  if (!parentRow) {
    console.warn(`Parent row with id ${parentId} not found.`);
    return "0.00";
  }

  const parentConcentration = parseFloat(parentRow.concentration) || 0;
  const percentActive = parseFloat(row.percent_active) || 0;
  const endFormulaConcentration = (parentConcentration * percentActive) / 100;

  return endFormulaConcentration.toFixed(2);
};

// Export data to Excel
export const exportToExcel = (treeData, concentrations, finalInciString = '') => {
  const worksheetData = [];
  const merge = [];
  let currentRow = 1; // Start at 1 to account for header row

  const calculateEndFormulaConcentration = (row, parentConcentration) => {
    const percentActive = parseFloat(row.percent_active) || 0;
    return ((parentConcentration * percentActive) / 100).toFixed(4);
  };

  treeData.forEach((row) => {
    if (!row.isSummary && row.parentId === null) {
      // Check if it's a main ingredient
      const startRow = currentRow;
      const concentration =
        parseFloat(concentrations[row.id]?.concentration) || 0;

      // Add the main ingredient
      const mainIngredientRow = {
        "No.": row.order || "",
        "SAP Code": row.sap_code || "",
        "Trade Name": row.trade_name || "",
        "INCI Name": row.inci_name || "", // Set INCI name for ingredients without sub-ingredients
        "EINECS NO": row.einecs_no || "",
        "CAS No.": row.cas_no || "",
        "% active in raw material/trade name": row.percent_active || "",
        Manufacturer: row.manufacturer || "",
        "Country of Origin": row.country_of_origin || "",
        REACH: row.reach || "",
        "Concentration on formula in %": concentration.toFixed(5),
        "Concentration in end formula": concentration.toFixed(4), // Set end formula concentration for ingredients without sub-ingredients
        Function: row.function || "",
        Restrictions: row.restrictions || "",
        Status: row.status || "",
        Price: row.price || "",
        Vegan: row.vegan || "",
        "ISO 16128 Natural Origin Ingredient":
          row.iso_16128_natural_origin_ingredient || "",
        Supplier: row.supplier || "",
        "No Animal Testing Declaration":
          row.no_animal_testing_declaration || "",
        "SDS (Validity 3 Years)": row.sds_validity_3_years || "",
        Organic: row.organic || "",
        "COSMOS Certification": row.cosmos_certification || "",
        "Gluten Free": row.gluten_free || "",
        GMO: row.gmo || "",
        Microplastic: row.microplastic || "",
        RSPO: row.rspo || "",
        "RSPO №": row.rspo_no || "",
        Fairtrade: row.fairtrade || "",
        "Origin of Raw Material": row.origin_of_raw_material || "",
        "Microbiological Analysis": row.microbiological_analysis || "",
        Solubility: row.solubility || "",
      };

      // Add sub-ingredients
      if (row.subingredients && row.subingredients.length > 0) {
        row.subingredients.forEach((sub, index) => {
          const subConcentration = calculateEndFormulaConcentration(
            sub,
            concentration
          );
          if (index === 0) {
            // For the first sub-ingredient, merge it with the main ingredient row
            mainIngredientRow["INCI Name"] = sub.inci_name || "";
            mainIngredientRow["EINECS NO"] = sub.einecs_no || "";
            mainIngredientRow["CAS No."] = sub.cas_no || "";
            mainIngredientRow["% active in raw material/trade name"] =
              sub.percent_active || "";
            mainIngredientRow["Concentration in end formula"] =
              subConcentration;
            worksheetData.push(mainIngredientRow);
          } else {
            // For subsequent sub-ingredients
            worksheetData.push({
              "No.": "",
              "SAP Code": "",
              "Trade Name": "",
              "INCI Name": sub.inci_name || "",
              "EINECS NO": sub.einecs_no || "",
              "CAS No.": sub.cas_no || "",
              "% active in raw material/trade name": sub.percent_active || "",
              Manufacturer: "",
              "Country of Origin": "",
              REACH: sub.reach || "",
              "Concentration on formula in %": "",
              "Concentration in end formula": subConcentration,
              Function: "",
              Restrictions: "",
              Status: "",
              Price: "",
              Vegan: "",
              "ISO 16128 Natural Origin Ingredient": "",
              Supplier: "",
              "No Animal Testing Declaration": "",
              "SDS (Validity 3 Years)": "",
              Organic: "",
              "COSMOS Certification": "",
              "Gluten Free": "",
              GMO: "",
              Microplastic: "",
              RSPO: "",
              "RSPO №": "",
              Fairtrade: "",
              "Origin of Raw Material": "",
              "Microbiological Analysis": "",
              Solubility: "",
            });
          }
          currentRow++;
        });

        // Merge cells for the main ingredient
        [
          "No.",
          "SAP Code",
          "Trade Name",
          "Manufacturer",
          "Country of Origin",
          "Concentration on formula in %",
          "Function",
          "Restrictions",
          "Status",
          "Price",
          "Vegan",
          "ISO 16128 Natural Origin Ingredient",
          "Supplier",
          "No Animal Testing Declaration",
          "SDS (Validity 3 Years)",
          "Organic",
          "COSMOS Certification",
          "Gluten Free",
          "GMO",
          "Microplastic",
          "RSPO",
          "RSPO №",
          "Fairtrade",
          "Origin of Raw Material",
          "Microbiological Analysis",
          "Solubility",
        ].forEach((field) => {
          merge.push({
            s: {
              r: startRow,
              c: Object.keys(mainIngredientRow).indexOf(field),
            },
            e: {
              r: currentRow - 1,
              c: Object.keys(mainIngredientRow).indexOf(field),
            },
          });
        });
      } else {
        // If there are no sub-ingredients, just add the main ingredient row
        worksheetData.push(mainIngredientRow);
        currentRow++;
      }
    }
  });

  const worksheet = XLSX.utils.json_to_sheet(worksheetData);

  // Apply merges
  worksheet["!merges"] = merge;

  // Set column widths
  worksheet["!cols"] = [
    { wch: 5 }, // No.
    { wch: 20 }, // SAP Code
    { wch: 30 }, // Trade Name
    { wch: 50 }, // INCI Name
    { wch: 15 }, // EINECS NO
    { wch: 15 }, // CAS No.
    { wch: 30 }, // % active in raw material/trade name
    { wch: 20 }, // Manufacturer
    { wch: 20 }, // Country of Origin
    { wch: 20 }, // REACH
    { wch: 25 }, // Concentration on formula in %
    { wch: 25 }, // Concentration in end formula
    { wch: 20 }, // Function
    { wch: 20 }, // Restrictions
    { wch: 20 }, // Status
    { wch: 15 }, // Price
    { wch: 15 }, // Vegan
    { wch: 30 }, // ISO 16128 Natural Origin Ingredient
    { wch: 20 }, // Supplier
    { wch: 30 }, // No Animal Testing Declaration
    { wch: 25 }, // SDS (Validity 3 Years)
    { wch: 15 }, // Organic
    { wch: 25 }, // COSMOS Certification
    { wch: 15 }, // Gluten Free
    { wch: 15 }, // GMO
    { wch: 15 }, // Microplastic
    { wch: 15 }, // RSPO
    { wch: 15 }, // RSPO №
    { wch: 15 }, // Fairtrade
    { wch: 25 }, // Origin of Raw Material
    { wch: 25 }, // Microbiological Analysis
    { wch: 20 }, // Solubility
  ];

  // Add INCI List section 5 rows below the main table 
  if (finalInciString) {
    // Determine the row to start the INCI list (5 rows after the last data row)
    const inciListStartRow = currentRow + 5;
    
    // Add the INCI List header
    XLSX.utils.sheet_add_aoa(
      worksheet, 
      [['INCI List (Final Declaration)']], 
      { origin: { r: inciListStartRow, c: 0 } }
    );
    
    // Add the INCI string in the next row
    XLSX.utils.sheet_add_aoa(
      worksheet, 
      [[finalInciString]], 
      { origin: { r: inciListStartRow + 1, c: 0 } }
    );
    
    // Merge cells for the INCI List to span multiple columns (from column 0 to column 3)
    merge.push({
      s: { r: inciListStartRow + 1, c: 0 },
      e: { r: inciListStartRow + 1, c: 3 }
    });
    
    // Update the worksheet merges
    worksheet["!merges"] = merge;
    
    // Ensure the sheet range includes our new content
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    if (inciListStartRow + 1 > range.e.r) {
      range.e.r = inciListStartRow + 1;
      worksheet['!ref'] = XLSX.utils.encode_range(range);
    }
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Ingredients");
  XLSX.writeFile(workbook, "Ingredients_Export.xlsx");
};

// Calculate and sort INCI names when total concentration reaches 100%
export const calculateInciNames = (selectedIngredients, concentrations, subIngredientsMap) => {
  const totalConcentration = Object.values(concentrations).reduce(
    (sum, item) => sum + (item.concentration || 0),
    0
  );

  if (totalConcentration === 100) {
    // First, we'll group ingredients and subingredients to maintain hierarchy
    const result = [];
    const processedInciNames = new Set();
    
    // Process ingredients in their order in the formula
    selectedIngredients.forEach((ingredient) => {
      const concentration =
        parseFloat(
          concentrations[`ingredient-${ingredient.id}`]?.concentration
        ) || 0;
      
      // Add main ingredient
      const mainIngredientItem = {
        inciName: ingredient.trade_name || ingredient.inci_name,
        concentration: concentration.toFixed(2),
        id: ingredient.id,
        type: 'main-ingredient',
        subingredients: []
      };
      
      result.push(mainIngredientItem);
      
      // Add subingredients under this main ingredient
      (subIngredientsMap[ingredient.id] || []).forEach((sub) => {
        const subPercentActive = parseFloat(sub.percent_active) || 0;
        const concentrationInEndFormula =
          (concentration * subPercentActive) / 100;
        
        mainIngredientItem.subingredients.push({
          inciName: sub.inci_name,
          concentration: concentrationInEndFormula.toFixed(2),
          id: sub.id,
          parentId: ingredient.id,
          parentName: ingredient.trade_name,
          type: 'subingredient',
          percentActiveInParent: subPercentActive
        });
        
        // Track that we've processed this INCI name
        processedInciNames.add(sub.inci_name);
      });
    });
    
    // Also calculate traditional flat INCI list for combining functionality
    const inciConcentrationMap = {};
    const inciSourcesMap = {};

    selectedIngredients.forEach((ingredient) => {
      const concentration =
        parseFloat(
          concentrations[`ingredient-${ingredient.id}`]?.concentration
        ) || 0;

      if (ingredient.inci_name) {
        if (inciConcentrationMap[ingredient.inci_name]) {
          inciConcentrationMap[ingredient.inci_name] += concentration;
          inciSourcesMap[ingredient.inci_name].push({
            id: ingredient.id,
            name: ingredient.trade_name,
            type: 'ingredient',
            concentration: concentration.toFixed(2)
          });
        } else {
          inciConcentrationMap[ingredient.inci_name] = concentration;
          inciSourcesMap[ingredient.inci_name] = [{
            id: ingredient.id,
            name: ingredient.trade_name,
            type: 'ingredient',
            concentration: concentration.toFixed(2)
          }];
        }
      }

      (subIngredientsMap[ingredient.id] || []).forEach((sub) => {
        const subPercentActive = parseFloat(sub.percent_active) || 0;
        const concentrationInEndFormula =
          (concentration * subPercentActive) / 100;

        if (inciConcentrationMap[sub.inci_name]) {
          inciConcentrationMap[sub.inci_name] += concentrationInEndFormula;
          inciSourcesMap[sub.inci_name].push({
            id: sub.id,
            parentId: ingredient.id,
            parentName: ingredient.trade_name,
            name: sub.inci_name,
            type: 'subingredient',
            concentration: concentrationInEndFormula.toFixed(2)
          });
        } else {
          inciConcentrationMap[sub.inci_name] = concentrationInEndFormula;
          inciSourcesMap[sub.inci_name] = [{
            id: sub.id,
            parentId: ingredient.id,
            parentName: ingredient.trade_name,
            name: sub.inci_name,
            type: 'subingredient',
            concentration: concentrationInEndFormula.toFixed(2)
          }];
        }
      });
    });

    const flatInciList = Object.entries(inciConcentrationMap)
      .map(([inciName, concentration]) => ({
        inciName,
        concentration: concentration.toFixed(2),
        sources: inciSourcesMap[inciName]
      }))
      .sort(
        (a, b) => parseFloat(b.concentration) - parseFloat(a.concentration)
      );
    
    return {
      hierarchical: result,
      flat: flatInciList
    };
  }

  return {
    hierarchical: [],
    flat: []
  };
};

// Get tree data path for MUI DataGrid
export const getTreeDataPath = (row, treeData) => {
  // Handle summary rows specially to ensure unique paths
  if (row.isSummary) {
    return ['Total'];
  }
  
  // Handle rows with undefined or null name values
  const rowName = row.trade_name || row.inci_name || `Row-${row.id}`;
  
  // For sub-ingredients (with parentId)
  if (row.parentId) {
    const parent = treeData?.find((item) => item.id === row.parentId);
    
    if (parent) {
      // Make sure parent name is also not null
      const parentName = parent.trade_name || parent.inci_name || `Parent-${parent.id}`;
      return [parentName, rowName];
    }
    
    // Fallback if parent not found
    return [`Unknown-Parent-${row.parentId}`, rowName];
  }
  
  // For main ingredients (no parentId)
  return [rowName];
};
