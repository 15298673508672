import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Autocomplete, TextField, CircularProgress, Paper, Popper } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * A reusable search autocomplete component
 * 
 * @param {Object} props - Component props
 * @param {Function} props.fetchSearchResults - Function to fetch search results (receives search query as parameter)
 * @param {Function} props.onSelect - Callback when an option is selected
 * @param {Function} props.getOptionLabel - Function to get the display label for each option
 * @param {string} props.label - Label for the search field
 * @param {number} props.minSearchLength - Minimum characters before search is triggered
 * @param {number} props.debounceTime - Debounce time in milliseconds
 * @param {Object} props.sx - Custom styles to apply to the Autocomplete
 * @param {boolean} props.freeSolo - Whether the input can contain values not specified in options
 * @param {Array} props.initialOptions - Initial array of options (optional)
 */
const SearchAutocomplete = ({
  fetchSearchResults,
  onSelect,
  getOptionLabel = (option) => option.name || "",
  label = "Search",
  minSearchLength = 3,
  debounceTime = 500,
  sx = {},
  freeSolo = true,
  initialOptions = []
}) => {
  const [options, setOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  // Memoize the fetch function to prevent it from causing infinite loops
  const memoizedFetch = useCallback(async (query) => {
    console.log(`[SearchAutocomplete] Memoized fetch for: "${query}"`);
    return await fetchSearchResults(query);
  }, [fetchSearchResults]);

  // Control when the search effect runs with a ref to prevent redundant searches
  useEffect(() => {
    // Don't search for short queries
    if (inputValue.length < minSearchLength) {
      setOptions(initialOptions);
      return;
    }

    console.log(`[SearchAutocomplete] Starting search for: "${inputValue}"`);

    const handler = setTimeout(async () => {
      // Set loading state first
      setLoading(true);
      
      try {
        console.log(`[SearchAutocomplete] Fetching results for: "${inputValue}"`);
        const results = await memoizedFetch(inputValue);
        console.log(`[SearchAutocomplete] Got ${results.length} results, setting options`);
        setOptions(results);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        console.log(`[SearchAutocomplete] Setting loading to false`);
        setLoading(false);
      }
    }, debounceTime);

    return () => {
      console.log(`[SearchAutocomplete] Cleaning up timeout for: "${inputValue}"`);
      clearTimeout(handler);
    };
  }, [inputValue, memoizedFetch, minSearchLength, debounceTime, initialOptions]);

  // Important: We don't need to add extra keys that would cause re-renders
  // The key difference from the working component is avoiding extra mappings here
  
  // Log whenever component re-renders and what triggered it
  console.log(`[SearchAutocomplete] Rendering with inputValue: "${inputValue}", loading: ${loading}, options: ${options.length}`);
  
  // Memoize handlers to prevent them from causing re-renders
  const handleInputChange = useCallback((event, newValue) => {
    console.log(`[SearchAutocomplete] Input changing from "${inputValue}" to "${newValue}"`);
    setInputValue(newValue);
  }, [inputValue]);

  const handleChange = useCallback((event, value) => {
    console.log('Selected ingredient:', value);
    onSelect(event, value);
  }, [onSelect]);
  
  // Force loading to false if input is too short - this helps avoid stuck loading state
  const effectiveLoading = inputValue.length < minSearchLength ? false : loading;
  
  // Memoize options to prevent unnecessary re-renders
  const memoizedOptions = useMemo(() => options, [options]);
  
  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={memoizedOptions}
      getOptionLabel={getOptionLabel}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      loading={effectiveLoading}
      isOptionEqualToValue={useMemo(() => (option, value) => {
        // Handle null cases
        if (!option || !value) return false;
        
        // Simple ID comparison if available
        if (option.id && value.id) return option.id === value.id;
        
        // Compare by label as last resort
        return getOptionLabel(option) === getOptionLabel(value);
      }, [getOptionLabel])}
      getOptionKey={useMemo(() => (option) => option?.id || (typeof option === 'string' ? option : 'option'), [])}
      disableListWrap={false}
      includeInputInList={true}
      filterSelectedOptions={false}
      filterOptions={useMemo(() => (x) => x, [])} // Pass through all options without filtering
      sx={{ width: "100%", ...sx }}
      renderInput={useMemo(() => (params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {effectiveLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      ), [label, effectiveLoading])}
      PaperComponent={useMemo(() => (props) => (
        <Paper
          {...props}
          sx={{
            width: "100%",
            boxSizing: "border-box",
          }}
        />
      ), [])}
      PopperComponent={useMemo(() => (props) => {
        const { anchorEl, ...popperProps } = props;
        return (
          <Popper
            {...popperProps}
            anchorEl={anchorEl}
            sx={{
              width: anchorEl ? anchorEl.clientWidth : undefined,
            }}
            placement="bottom-start"
            modifiers={[
              {
                name: "flip",
                enabled: true,
              },
              {
                name: "preventOverflow",
                options: {
                  altAxis: true,
                  boundary: "viewport",
                },
              },
            ]}
          />
        );
      }, [])}
    />
  );
};

SearchAutocomplete.propTypes = {
  fetchSearchResults: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  minSearchLength: PropTypes.number,
  debounceTime: PropTypes.number,
  sx: PropTypes.object,
  freeSolo: PropTypes.bool,
  initialOptions: PropTypes.array
};

export default SearchAutocomplete;