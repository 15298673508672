// src/components/Login.jsx
import React, { useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { Box, TextField, Button, Typography, Paper, Container, InputAdornment, IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e && e.preventDefault(); // Prevent form submission if coming from form submit
    
    // Basic validation
    if (!email || !password) {
      setError("Email and password are required");
      return;
    }
    
    try {
      await login(email, password);
      navigate("/home");
    } catch (error) {
      console.error("Login error:", error);
      setError(error.response?.data?.message || error.message || "Invalid email or password");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4
          }}
        >
          <img 
            src="/Inci_logo_only-white.webp" 
            alt="INCI Navigator Logo" 
            style={{ 
              width: "80px", 
              height: "auto", 
              marginBottom: "16px" 
            }} 
          />
          <Typography 
            variant="h3" 
            sx={{ 
              fontWeight: 600,
              textAlign: "center",
              letterSpacing: "-0.5px"
            }}
          >
            INCI Navigator
          </Typography>
        </Box>
        
        <Paper 
          elevation={1} 
          sx={{ 
            width: "100%",
            p: 4,
            borderRadius: 3,
            mb: 3
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 3, 
              fontWeight: 600,
              fontSize: "1.75rem",
              textAlign: "center"
            }}
          >
            Sign in
          </Typography>
          
          <form onSubmit={handleLogin}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ 
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1.5,
                }
              }}
              InputProps={{
                sx: { padding: '12px 14px' }
              }}
            />
            
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ 
                mb: 3,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1.5,
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { padding: '12px 14px' }
              }}
            />
            
            {error && (
              <Typography 
                color="error" 
                sx={{ 
                  mb: 2, 
                  fontSize: "0.875rem",
                  textAlign: "center"
                }}
              >
                {error}
              </Typography>
            )}
            
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              size="large"
              type="submit"
              sx={{ 
                mt: 1,
                py: 1.5,
                fontWeight: 600,
                fontSize: "1rem",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: 2
              }}
            >
              Sign In
            </Button>
          </form>
        </Paper>
        
        <Paper 
          elevation={1} 
          sx={{ 
            width: "100%",
            p: 3,
            borderRadius: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography variant="body1" sx={{ mr: 1 }}>
            Don't have an account?
          </Typography>
          <Button 
            component={Link} 
            to="/register" 
            color="primary"
            sx={{ 
              fontWeight: 600,
              textTransform: "none"
            }}
          >
            Create an account
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;