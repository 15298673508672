import React, { useState, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box, Typography, Card, CardContent, Divider, useTheme, Paper } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import config from "../config";
import { ThemeContext } from '../contexts/ThemeContext';

const api = axios.create({
  baseURL: config.API_URL,
});


const PublicFormulaView = () => {
  const { publicUrl } = useParams();
  const [formula, setFormula] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inciNames, setInciNames] = useState([]);
  const theme = useTheme();
  const { mode } = useContext(ThemeContext);


  const fetchFormula = async () => {
    try {
      const response = await api.get(`/api/formulas/view/${publicUrl}`);
      console.log("Fetched Formula Data: ", response.data);
      setFormula(response.data);
    } catch (err) {
      console.error('Error fetching formula', err);
      setError('Failed to load formula. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormula();
  }, [publicUrl]);

  const calculateInciNames = (ingredients, concentrations) => {
    const result = [];
    ingredients.forEach(ingredient => {
      const ingredientId = `ingredient-${ingredient.id}`;
      const concentration = parseFloat(concentrations[ingredientId]?.concentration) || 0;

      const updatedSubingredients = (ingredient.subingredients || []).map(sub => {
        const subPercentActive = parseFloat(sub.percent_active) || 0;
        const concentrationInEndFormula = (concentration * subPercentActive) / 100;
        return {
          inciName: sub.inci_name,
          concentration: concentrationInEndFormula.toFixed(2),
        };
      });

      if (ingredient.inci_name) {
        result.push({
          inciName: ingredient.inci_name,
          concentration: concentration.toFixed(2),
        });
      }

      result.push(...updatedSubingredients);
    });

    // Sort INCI names by concentration, descending
    result.sort((a, b) => parseFloat(b.concentration) - parseFloat(a.concentration));
    return result;
  };

  useEffect(() => {
    if (formula) {
      const inciNamesList = calculateInciNames(formula.data.ingredients, formula.data.concentrations);
      setInciNames(inciNamesList);
    }
  }, [formula]);

  const columns: GridColDef[] = [
    { field: "order", headerName: "Order", flex: 1, sortable: false, filterable: false },
    { field: "sap_code", headerName: "SAP Code", flex: 1 },
    { field: "trade_name", headerName: "Trade Name", flex: 1 },
    { field: "inci_name", headerName: "INCI Name", flex: 1 },
    { field: "einecs_no", headerName: "EINECS NO", flex: 1 },
    { field: "cas_no", headerName: "CAS No", flex: 1 },
    { field: "percent_active", headerName: "% Active", flex: 1 },
    { field: "manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "country_of_origin", headerName: "Country of Origin", flex: 1 },
    { field: "reach", headerName: "REACH", flex: 1 },
    { field: "function", headerName: "Function", flex: 1 },
    { field: "restrictions", headerName: "Restrictions", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "vegan", headerName: "Vegan", flex: 1 },
    {
      field: "iso_16128_natural_origin_ingredient",
      headerName: "ISO 16128 Natural Origin Ingredient",
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier", flex: 1 },
    {
      field: "no_animal_testing_declaration",
      headerName: "No Animal Testing Declaration",
      flex: 1,
    },
    {
      field: "sds_validity_3_years",
      headerName: "SDS (Validity 3 Years)",
      flex: 1,
    },
    { field: "organic", headerName: "Organic", flex: 1 },
    {
      field: "cosmos_certification",
      headerName: "COSMOS Certification",
      flex: 1,
    },
    { field: "gluten_free", headerName: "Gluten Free", flex: 1 },
    { field: "gmo", headerName: "GMO", flex: 1 },
    { field: "microplastic", headerName: "Microplastic", flex: 1 },
    { field: "rspo", headerName: "RSPO", flex: 1 },
    { field: "rspo_no", headerName: "RSPO №", flex: 1 },
    { field: "fairtrade", headerName: "Fairtrade", flex: 1 },
    {
      field: "origin_of_raw_material",
      headerName: "Origin of Raw Material",
      flex: 1,
    },
    {
      field: "microbiological_analysis",
      headerName: "Microbiological Analysis",
      flex: 1,
    },
    { field: "solubility", headerName: "Solubility", flex: 1 },
    {
      field: "concentration",
      headerName: "Concentration on formula in %",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return (
            <span style={{ fontWeight: "bold" }}>
              {params.value.toFixed(2)}%
            </span>
          );
        }
      },
    },
    {
      field: "concentrationInEndFormula",
      headerName: "Concentration in end formula",
      flex: 1,
      renderCell: (params) => {
        if (params.row.isSummary) {
          return null; // No need to render for summary rows
        }
        const endFormulaConcentration = params.row.concentrationInEndFormula 
          ? parseFloat(params.row.concentrationInEndFormula).toFixed(2) 
          : 'N/A';
        return <span>{endFormulaConcentration}%</span>;
      },
    },
  ];
  
  // The rows useMemo is the same as in the previous version
  const rows = useMemo(() => {
    if (!formula) return [];
    
    const rows = [];
    formula.data.ingredients.forEach((ingredient, index) => {
      const ingredientId = `ingredient-${ingredient.id}`;
      const concentration = parseFloat(formula.data.concentrations[ingredientId]?.concentration || '0.00').toFixed(2);

      // Add main ingredient row
      rows.push({
        ...ingredient,
        id: `${ingredient.id}`,
        parentId: null,
        order: index + 1,
        concentration,
        concentrationInEndFormula: concentration,
      });

      // Add subingredients rows, ensure they are correctly connected
      (ingredient.subingredients || []).forEach((sub) => {
        rows.push({
          ...sub,
          id: `${ingredient.id}-${sub.id}`, // Combine parent ID with sub-ingredient ID
          parentId: `${ingredient.id}`,
          concentrationInEndFormula: parseFloat(sub.concentrationInEndFormula || '0.00').toFixed(2),
        });
      });
    });

    return rows;
  }, [formula]);
  
  

  useEffect(() => {
    if (rows && rows.length > 0) {
      console.log('Final Rows passed to DataGrid:', rows);
    } else {
      console.warn('Rows not ready yet or empty');
    }
  }, [rows]);

  // Checking rows data
  useEffect(() => {
    rows.forEach((row) => {
      if (!row.id) {
        console.error('Row is missing an id:', row);
      }
      if (!row.concentration && row.concentration !== 0) {
        console.error('Row is missing concentration:', row);
      }
      if (!row.concentrationInEndFormula && row.concentrationInEndFormula !== 0) {
        console.error('Row is missing concentrationInEndFormula:', row);
      }
    });
  }, [rows]);

  const getTreeDataPath = (row) => {
    if (!row) return [];
    
    if (row.parentId) {
      const parent = rows.find((item) => item.id === row.parentId);
      return parent ? [parent.trade_name, row.trade_name || row.inci_name] : [row.trade_name || row.inci_name];
    }

    return [row.trade_name || row.inci_name];
  };

  const groupingColDef = {
    headerName: "",
    width: 50,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        p: 3,
        textAlign: 'center' 
      }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            maxWidth: '600px',
            backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
          }}
        >
          <Typography variant="h5" color="error" gutterBottom>
            Error Loading Formula
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
        </Paper>
      </Box>
    );
  }

  if (!formula) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        p: 3,
        textAlign: 'center' 
      }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 2,
            maxWidth: '600px',
            backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Formula Not Found
          </Typography>
          <Typography variant="body1">
            The requested formula could not be found or may have expired.
          </Typography>
        </Paper>
      </Box>
    );
  }

  // Function to calculate the final INCI string (sorted by concentration and formatting rules)
  const getFinalInciString = () => {
    if (!inciNames || inciNames.length === 0) return "N/A";
    
    // Sort according to standard INCI rules (descending concentration, alphabetical for <1%)
    const sortedList = [...inciNames].sort((a, b) => {
      const concA = parseFloat(a.concentration) || 0;
      const concB = parseFloat(b.concentration) || 0;

      if (concA >= 1 && concB >= 1) {
        return concB - concA; // Sort descending > 1%
      } else if (concA < 1 && concB < 1) {
        // Sort alphabetically < 1% (case-insensitive)
        const nameA = (a.inciName || "").toUpperCase();
        const nameB = (b.inciName || "").toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else if (concA >= 1 && concB < 1) {
        return -1; // Ingredients >= 1% come first
      } else {
        return 1; // Ingredients >= 1% come first
      }
    });

    // Join names with ", "
    return sortedList.map(item => item.inciName).join(", ");
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', p: { xs: 2, md: 4 } }}>
      <Card 
        elevation={3} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          mb: 4,
          backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <Typography 
            variant="h4" 
            gutterBottom
            sx={{ 
              fontWeight: 600,
              color: mode === 'dark' ? 'primary.main' : 'primary.dark',
              mb: 3
            }}
          >
            {formula.name}
          </Typography>
          
          <Divider sx={{ mb: 3 }} />
          
          <Typography 
            variant="body2" 
            sx={{ 
              mb: 2,
              color: 'text.secondary',
              fontStyle: 'italic' 
            }}
          >
            Public formula view - shared formula data
          </Typography>
        </CardContent>
      </Card>
      
      {/* Ingredients data grid */}
      <Card 
        elevation={2} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          mb: 4,
          backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box sx={{ 
            p: 2, 
            borderBottom: 1, 
            borderColor: 'divider',
            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)'
          }}>
            <Typography variant="h6" fontWeight="600">
              Formula Ingredients
            </Typography>
          </Box>
          
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGridPro
              rows={rows}
              columns={columns}
              treeData
              getTreeDataPath={getTreeDataPath}
              disableColumnReorder
              groupingColDef={groupingColDef}
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell': {
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: `1px solid ${theme.palette.divider}`,
                  backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
                },
              }}
            />
          </Box>
        </CardContent>
      </Card>
      
      {/* INCI Names section */}
      <Card 
        elevation={2} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          backgroundColor: mode === 'dark' ? 'background.paper' : 'background.default',
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box sx={{ 
            p: 2, 
            borderBottom: 1, 
            borderColor: 'divider',
            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)'
          }}>
            <Typography variant="h6" fontWeight="600">
              INCI Names
            </Typography>
          </Box>
          
          <Box sx={{ p: 3 }}>
            {/* Detailed INCI List */}
            <Box 
              sx={{ 
                mb: 4, 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' },
                gap: 2 
              }}
            >
              {inciNames.map((item, index) => (
                <Box 
                  key={index}
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="body2" fontWeight="500">
                    {item.inciName}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      fontWeight: '600',
                      ml: 2
                    }}
                  >
                    {item.concentration}%
                  </Typography>
                </Box>
              ))}
            </Box>
            
            {/* Final INCI Declaration */}
            <Box sx={{ mt: 4 }}>
              <Typography 
                variant="subtitle1" 
                fontWeight="600" 
                sx={{ mb: 2 }}
              >
                Final INCI Declaration
              </Typography>
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  borderRadius: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
                }}
              >
                <Typography 
                  variant="body1" 
                  sx={{ 
                    lineHeight: 1.7,
                    wordWrap: 'break-word' 
                  }}
                >
                  {getFinalInciString()}
                </Typography>
              </Paper>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PublicFormulaView;