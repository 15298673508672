// src/components/Register.jsx
import React, { useState } from 'react';
import AuthService from '../services/auth.service';
import { Box, TextField, Button, Typography, Paper, Container, InputAdornment, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!username || !email || !password) {
      setError("All fields are required");
      return;
    }
    
    try {
      const response = await AuthService.register(username, email, password);
      setSuccess(response.data.message || "Registration successful! You can now sign in.");
      setError('');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setSuccess('');
      setError(error.response?.data?.message || 'Registration failed');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4
          }}
        >
          <img 
            src="/Inci_logo_only-white.webp" 
            alt="INCI Navigator Logo" 
            style={{ 
              width: "80px", 
              height: "auto", 
              marginBottom: "16px" 
            }} 
          />
          <Typography 
            variant="h3" 
            sx={{ 
              fontWeight: 600,
              textAlign: "center",
              letterSpacing: "-0.5px"
            }}
          >
            INCI Navigator
          </Typography>
        </Box>
        
        <Paper 
          elevation={1} 
          sx={{ 
            width: "100%",
            p: 4,
            borderRadius: 3,
            mb: 3
          }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 3, 
              fontWeight: 600,
              fontSize: "1.75rem",
              textAlign: "center"
            }}
          >
            Create an account
          </Typography>
          
          <form onSubmit={handleRegister}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ 
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1.5,
                }
              }}
              InputProps={{
                sx: { padding: '12px 14px' }
              }}
            />
            
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ 
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1.5,
                }
              }}
              InputProps={{
                sx: { padding: '12px 14px' }
              }}
            />
            
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ 
                mb: 3,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1.5,
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { padding: '12px 14px' }
              }}
            />
            
            {error && (
              <Typography 
                color="error" 
                sx={{ 
                  mb: 2, 
                  fontSize: "0.875rem",
                  textAlign: "center"
                }}
              >
                {error}
              </Typography>
            )}
            
            {success && (
              <Typography 
                color="success.main" 
                sx={{ 
                  mb: 2, 
                  fontSize: "0.875rem",
                  textAlign: "center"
                }}
              >
                {success}
              </Typography>
            )}
            
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              size="large"
              type="submit"
              sx={{ 
                mt: 1,
                py: 1.5,
                fontWeight: 600,
                fontSize: "1rem",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: 2
              }}
            >
              Register
            </Button>
          </form>
        </Paper>
        
        <Paper 
          elevation={1} 
          sx={{ 
            width: "100%",
            p: 3,
            borderRadius: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography variant="body1" sx={{ mr: 1 }}>
            Already have an account?
          </Typography>
          <Button 
            component={Link} 
            to="/login" 
            color="primary"
            sx={{ 
              fontWeight: 600,
              textTransform: "none"
            }}
          >
            Sign in
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default Register;