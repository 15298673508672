import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { IconButton, Tooltip, Switch, FormControlLabel } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

// Style 1: Simple Icon Toggle
export const ThemeIconToggle = () => {
  const { mode, toggleTheme } = useContext(ThemeContext);
  
  return (
    <Tooltip title={mode === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}>
      <IconButton onClick={toggleTheme} color="inherit" size="small">
        {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>
    </Tooltip>
  );
};

// Style 2: Apple-style Switch Toggle
export const ThemeSwitch = () => {
  const { mode, toggleTheme } = useContext(ThemeContext);
  
  return (
    <FormControlLabel
      control={
        <Switch
          checked={mode === 'dark'}
          onChange={toggleTheme}
          name="themeSwitch"
          inputProps={{ 'aria-label': 'toggle theme' }}
        />
      }
      label={
        <React.Fragment>
          {mode === 'light' ? <LightModeIcon fontSize="small" style={{ marginRight: '4px' }} /> : <DarkModeIcon fontSize="small" style={{ marginRight: '4px' }} />}
          {mode === 'light' ? 'Light' : 'Dark'}
        </React.Fragment>
      }
      labelPlacement="start"
      sx={{ 
        margin: 0,
        '& .MuiFormControlLabel-label': {
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }
      }}
    />
  );
};

// Default export the icon toggle
export default ThemeIconToggle;