import React, { useContext } from 'react';
import { GridToolbarContainer, GridFooterContainer, GridFooter, GridToolbarColumnsButton } from '@mui/x-data-grid-pro';
import { Box, Typography, useTheme, Button, styled, Divider, Badge } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { ThemeContext } from '../../contexts/ThemeContext';
import { ActionButton } from '../common';

// Apple-style gradient button styling
const StyledActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 8,
  fontWeight: 500,
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(10, 132, 255, 0.12)' 
      : 'rgba(0, 122, 255, 0.08)'
  }
}));

const IngredientsToolbar = ({ onExportToExcel, onGeneratePublicUrl }) => {
  const theme = useTheme();
  
  return (
    <GridToolbarContainer
      sx={{
        padding: '12px 16px',
        gap: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.03)' 
          : 'rgba(0, 0, 0, 0.02)',
      }}
    >
      <StyledActionButton 
        color="primary"
        startIcon={<DownloadIcon />}
        onClick={onExportToExcel}
        size="small"
      >
        Export to Excel
      </StyledActionButton>
      
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      
      <StyledActionButton
        color="primary"
        startIcon={<ShareIcon />}
        onClick={onGeneratePublicUrl}
        size="small"
      >
        Share Formula
      </StyledActionButton>
      
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      
      <GridToolbarColumnsButton 
        size="small" 
        startIcon={<ViewColumnIcon />}
        sx={{ 
          textTransform: 'none',
          borderRadius: 2,
          fontWeight: 500,
        }}
      />
    </GridToolbarContainer>
  );
};

const IngredientsFooter = ({ totalConcentration }) => {
  const theme = useTheme();
  const { mode } = useContext(ThemeContext);
  
  const total = parseFloat(totalConcentration) || 0;
  const isComplete = Math.abs(100 - total) < 0.01; // Consider 99.99% to 100.01% as complete
  const isWarning = total > 100.01; // Over 100%
  
  return (
    <GridFooterContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          backgroundColor: mode === 'dark' 
            ? 'background.card' 
            : 'background.paper',
          borderTop: `1px solid ${theme.palette.divider}`,
          padding: '12px 16px',
          boxShadow: mode === 'dark'
            ? '0 -2px 8px rgba(0, 0, 0, 0.2)'
            : '0 -2px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        {/* This part will style the "Total Rows: X" */}
        <GridFooter
          sx={{
            border: 'none',
            "& .MuiTablePagination-root": {
              color: 'text.primary',
            },
            "& .MuiTablePagination-caption": {
              fontWeight: 500,
            },
            "& .MuiTablePagination-selectIcon": {
              color: 'primary.main',
            },
            "& .MuiTablePagination-actions": {
              "& .MuiIconButton-root": {
                color: 'primary.main',
              }
            }
          }}
        />

        {/* This is custom footer content */}
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Badge
            badgeContent={isWarning ? "!" : null}
            color={isWarning ? "error" : "primary"}
            sx={{
              "& .MuiBadge-badge": {
                right: -10,
                top: -5,
              }
            }}
          >
            <Typography 
              variant="body1" 
              fontWeight="600"
              sx={{
                color: isComplete 
                  ? 'success.main' 
                  : isWarning 
                    ? 'error.main' 
                    : 'text.primary',
                backgroundColor: isComplete 
                  ? (mode === 'dark' ? 'rgba(48, 209, 88, 0.15)' : 'rgba(52, 199, 89, 0.1)') 
                  : isWarning 
                    ? (mode === 'dark' ? 'rgba(255, 69, 58, 0.15)' : 'rgba(255, 59, 48, 0.1)')
                    : 'transparent',
                padding: isComplete || isWarning ? '6px 12px' : '6px 0',
                borderRadius: 2
              }}
            >
              Total: {total.toFixed(2)}%
            </Typography>
          </Badge>
        </Box>
      </Box>
    </GridFooterContainer>
  );
};

export { IngredientsToolbar, IngredientsFooter };